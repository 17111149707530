export default [
    {
        path: '/feedback',
        name: 'feedback',
        meta: { title: ' 会员反馈' },
        component: () => import('@/components/Member/Feedback'),
    },
    {
        path: '/questionnaire',
        name: 'Questionnaire',
        meta: { parent: 'feedback', name: 'Questionnaire', title: ' 问卷调查列表' },
        component: () => import('@/views/questionnaire/list'),
    },
    {
        path: '/questionnaireEdit',
        name: 'QuestionnaireEdit',
        meta: { parent: 'feedback', name: 'Questionnaire', title: ' 问卷调查编辑' },
        component: () => import('@/views/questionnaire/edit'),
    },
    {
        path: '/questionnaireDetail',
        name: 'QuestionnaireDetail',
        meta: { parent: 'feedback', name: 'Questionnaire', title: ' 问卷调查详情' },
        component: () => import('@/views/questionnaire/detail'),
    },
    {
        path: '/questionnaireReport/:id',
        name: 'QuestionnaireReport',
        meta: { parent: 'feedback', name: 'Questionnaire', title: ' 问卷调查报表' },
        component: () => import('@/views/questionnaire/report'),
    },
    {
        path: '/feedbackdetail',
        name: 'feedbackdetail',
        meta: { parent: 'feedback', name: 'parent' },
        component: () => import('@/components/Member/FeedbackDetail'),
    },
    // {
    //     path: "/ticketverify",
    //     name: "ticketsverify",
    //     meta: { title: " 小票审核" },
    //     component: () => import('@/components/Tickets/TicketsVerify'),
    // },
    {
        path: '/ocrTicketVerifyEdit',
        name: 'ocrTicketVerifyEdit',
        meta: { parent: 'ticketPoint', name: 'ocrTicketVerifyList', title: ' ocr小票审核 编辑' },
        component: () => import('@/views/OcrTicketVerify/edit'),
    },
    {
        path: '/ocrTicketVerifyTest',
        name: 'ocrTicketVerifyTest',
        meta: { parent: 'ticketPoint', name: 'ocrTicketVerifyTest', title: ' ocr小票审核 测试' },
        component: () => import('@/views/OcrTicketVerify/test'),
    },
    {
        path: '/ocrTicketVerifyList',
        name: 'ocrTicketVerifyList',
        meta: { parent: 'ticketPoint', name: 'ocrTicketVerifyList', title: ' OCR模版管理' },
        component: () => import('@/views/OcrTicketVerify/list'),
    },
    {
        path: '/ocrTrafficManagement',
        name: 'ocrTrafficManagement',
        meta: { parent: 'ticketPoint', name: 'ocrTrafficManagement', title: ' OCR流量管理' },
        component: () => import('@/views/OcrTrafficManagement/index'),
    },
    {
        path: '/ticketverify',
        name: 'ticketsverify',
        meta: { parent: 'ticketPoint', name: 'ticketsverify', title: ' 小票审核' },
        component: () => import('@/views/TicketVerify'),
    },
    {
        path: '/ticketdetail',
        name: 'ticketdetail',
        meta: { parent: 'ticketsverify', name: 'parent' },
        component: () => import('@/components/Tickets/TicketDetail'),
    },
    {
        path: '/liveTelecast',
        name: 'liveTelecast',
        meta: { title: '小程序直播' },
        component: () => import('@/components/LiveTelecast/LiveTelecast'),
    },
    {
        path: '/goodsoverview',
        name: 'goodsoverview',
        meta: { parent: 'liveTelecast', name: 'parent', title: '商品概览' },
        component: () => import('@/components/LiveTelecast/GoodsOverview'),
    },
    // 小票设备在线状态
    {
        path: '/ticketequipment',
        name: 'ticketequipment',
        meta: { parent: 'ticketequipment', name: 'name', title: '小票设备管理' },
        component: () => import('@/components/Tickets/TicketEquipment'),
    },
    // 通知中心
    {
        path: '/NotificationCenter',
        name: 'NotificationCenter',
        meta: { parent: 'NotificationCenter', name: 'name' },
        component: () => import('@/views/NotificationCenter'),
    },
];
