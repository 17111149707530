const parent = 'poster-submit';

export default [
    {
        path: "/advertisement",
        name: "advertisement",
        meta: { parent, name: 'name', title: "智能广告" },
        component: () => import('@/components/Advertisement'),
    },
    {
        path: "/advertisementDetail",
        name: "advertisementDetail",
        meta: { parent, name: 'advertisement', title: "智能广告详情" },
        component: () => import('@/components/Advertisement/AdvertisementDetail'),
    },
    {
        path: "/brandPromotion",
        name: "brandPromotion",
        meta: { parent, name: 'name', title: "品牌活动宣传" },
        component: () => import('@/components/BrandPromotion'),
    },
];
