<template lang="">
	<svg class="svg-icon" aria-hidden="true">
		<use :xlink:href="iconName" />
	</svg>
</template>

<script>
export default {
	name: 'SvgIcon',
	props: {
		iconClass: {
			type: String,
			required: true,
		},
	},
	computed: {
		iconName() {
			return `#icon-${this.iconClass}`
		},
	},
}
</script>

<style>
.svg-icon {
	width: 1em;
	height: 1em;
	vertical-align: -0.15em;
	fill: currentColor;
	overflow: hidden;
}
</style>
