'use strict'
import Vue from 'vue'

const _children =()=>({
    id: '',
    error: false,
    status: false, //  显示:true ,不显示:false
    isTimeShow:false,
    start_at:'',
    end_at:"",
    data_time:[],
    show_time:false,
    img_url: '', // 图片链接
    target:1,
    target_id: '', //
    target_type: '', //
    is_need_login: 0, // 0：不需要登录使用 1：需要登录使用
})
const defaultWxScreenAd =()=>({
    type: 19,
    status: false,
    path: 'weixinScreenAd.vue',
    editPath: 'editScreenAd.vue',
    children:_children()
})
const _fixprops =()=>( {
    wxScreenAd: defaultWxScreenAd(),
    wxPop: {
        type: 1,
        status: false,
        path: 'weixinPop.vue',
        editPath: 'editPop.vue',
        popup_limit: '1', // 1: 每天仅首次打开小程序弹出 2: 每次打开小程序都弹出
        children:[_children()]
    },
    wxFloat:{
        type: 2,
        status: false,
        path: 'weixinFloat.vue',
        editPath: 'editFloat.vue',
        children:_children()
    },
    wxSearch:{
        type: 18,
        status: true,
        path: '',
        editPath: 'editSearch.vue',
    },
    wxGoods:{
        type: 3,
        status: false, //闪购商品底部，免费商品底部
        error:false,
        show_title: true, //是否显示标题
        title_name:"闪购好物",
        bg_color: '#262424',
        text_color: '#DEAF7D',
        title_custom: false, //标题图片是否自定义
        img_url: '',
        target_id: '',
        target_type: '',
        path: 'immobilizationGoods.vue',
        editPath: 'editShelfGoods.vue',
    },
    wxCoupon:{
        type: 4,
        status: false, //闪购商品底部，免费商品底部
        error:false,
        show_title: true, //是否显示标题
        title_name:"心选好券",
        bg_color: '#262424',
        text_color: '#DEAF7D',
        path: 'weixinCouponGD.vue',
        editPath: 'editCouponGD.vue',
        title_custom: false, //标题图片是否自定义
        img_url: '',
        target_id: '',
        target_type: '',
    }
})
const applet = {
    state: () => ({
        appletProps:[],
        fixedProps:{
            wxScreenAd:defaultWxScreenAd(),
            wxPop:{
                type: 1,
                status: false,
                path: 'weixinPop.vue',
                editPath: 'editPop.vue',
                popup_limit: '1', // 1: 每天仅首次打开小程序弹出 2: 每次打开小程序都弹出
                children:[_children()]
            },
            wxFloat:{
                type: 2,
                status: false,
                path: 'weixinFloat.vue',
                editPath: 'editFloat.vue',
                children:_children()
            },
            wxGoods:{
                type: 3,
                status: false, //闪购商品底部，免费商品底部
                error:false,
                show_title: true, //是否显示标题
                title_name:"闪购好物",
                bg_color: '#262424',
                text_color: '#DEAF7D',
                path: 'immobilizationGoods.vue',
                editPath: 'editShelfGoods.vue',
                title_custom: false, //标题图片是否自定义
                img_url: '',
                target_id: '',
                target_type: '',
            },
            wxCoupon:{
                type: 4,
                status: false, //闪购商品底部，免费商品底部
                error:false,
                show_title: true, //是否显示标题
                title_name:"心选好券",
                bg_color: '#262424',
                text_color: '#DEAF7D',
                path: 'weixinCouponGD.vue',
                editPath: 'editCouponGD.vue',
                title_custom: false, //标题图片是否自定义
                img_url: '',
                target_id: '',
                target_type: '',
            },
            wxSearch:{
                type: 18,
                status: true,
                path: '',
                editPath: 'editSearch.vue',
            },
        },
        defaultWxScreenAd:defaultWxScreenAd(),
        cssData:{},
        couponData:[],
        productList:[],
        ticketList:[],
        lightList:[],
        titleList:[],
        activityList:[],
        config:{},
    }),
    mutations: {
        // 缓存货架数据
        ['CACHE_DATA'](state,data) {
            const {key,params} = data
            state[key] = params
        },
        ['UPDATE_CSSDATA'](state,data) {
            state.cssData = {...data}
        },
        // 变更固定组件状态
        ['UPDATE_FIXPROP'](state, data) {
            const {key,status} = data
            state.fixedProps[key].status = status
        },
        ['DEFAULT_AD'](state){
            state.fixedProps.wxScreenAd = defaultWxScreenAd()
        },
        ['CHANGE_PROP'](state, data) {
            const {index, params} = data
            if(params.children){
                if(Array.isArray(params.children)){
                    state.appletProps.splice(index, 1, {...params})
                }else{
                    state.appletProps[index].error = params.error
                    state.appletProps[index].children.error = params.children.error
                }
                
            }else{
                state.appletProps[index].error = params.error
            }
            
            console.log(state.appletProps[0])
        },
        ['CLOSE_FIXPROP'](state, data) {
            const {key} = data
            delete state.fixedProps[key]
        },
        // 变更可拖动组件配置
        ['ADD_PROP'](state, data) {
            const {key, params} = data
            state.appletProps.splice(key,0,params)
            console.log(state)
        },
        ['DEL_PROP'](state, key) {

            state.appletProps.splice(key,1)
        },
        ['MOVE_PROP'](state, data) {
            const {newIndex, oldIndex} = data
            const _param = state.appletProps[oldIndex]
            state.appletProps.splice(oldIndex,1)
            state.appletProps.splice(newIndex,0,_param)
        },
        ['CLEAR_PROP'](state) {
            state.appletProps = []
            state.fixedProps = _fixprops();
        },
        ['UPDATE_PROP'](state,data) {
            console.log(state.fixedProps)
            const fixarr = [1,2,3,4,18,19]
            const fixTitle = {
                1: "wxPop", 2:"wxFloat", 3: "wxGoods", 4: "wxCoupon", 18: "wxSearch", 19: "wxScreenAd"
            }
            state.appletProps = data.filter(el => !fixarr.includes(el.type))
            const _fixprops = data.filter(el => fixarr.includes(el.type))
            console.log("固定组件-----------",_fixprops)
            if(_fixprops.length > 0){
                _fixprops.forEach(element => {
                    const key = fixTitle[element.type]
                    console.log("key:::::",key,state.fixedProps[key])
                    state.fixedProps[key] = element

                    // 弹窗广告轮播后需兼容旧数据
                    if(element.type == 1 && !(element.children instanceof Array) ) {
                        state.fixedProps.wxPop.children = [element.children]
                        state.fixedProps.wxPop.popup_limit = element.children[0].popup_limit
                    }

                    if(element.type == 4){
                        state.fixedProps.wxGoods.status = false
                        if(!element.hasOwnProperty('title_custom')) { // 0204底部货架优化后给旧数据添加默认值
                            state.fixedProps.wxCoupon.title_custom = false
                            state.fixedProps.wxCoupon.img_url = ''
                            state.fixedProps.wxCoupon.target_id = ''
                            state.fixedProps.wxCoupon.target_type = ''
                        }
                    }
                    if(element.type == 3){
                        state.fixedProps.wxCoupon.status = false
                        if(!element.hasOwnProperty('title_custom')) { // 0204底部货架优化后给旧数据添加默认值
                            state.fixedProps.wxGoods.title_custom = false
                            state.fixedProps.wxGoods.img_url = ''
                            state.fixedProps.wxGoods.target_id = ''
                            state.fixedProps.wxGoods.target_type = ''
                        }
                    }
                });
                
            }
            
        }
    },
    actions: {
        // appletProps({ commit, state}, data) {
        //     commit(APPLET_PROPS)
        //     console.log(data)
        // }
    }
}

export default applet