// 短信群发
const parent = 'MassTexting';

export default [
    // 短信群发
    {
        path: '/massTexting',
        name: 'MassTexting',
        meta: { parent, name: 'name' },
        component: () => import('@/views/MassTexting/Texting/index'),
    },
    // 短信群发 -- 编辑
    {
        path: '/massTextingEdit',
        name: 'MassTextingEdit',
        meta: { parent, name: 'MassTexting' },
        component: () => import('@/views/MassTexting/Texting/edit'),
    },
    // 短信群发 -- 详情
    {
        path: '/massTextingDetail',
        name: 'MassTextingDetail',
        meta: { parent, name: 'MassTexting' },
        component: () => import('@/views/MassTexting/Texting/detail'),
    },
    // 模板管理
    {
        path: '/templateManagement',
        name: 'TemplateManagement',
        meta: { parent, name: 'name' },
        component: () => import('@/views/MassTexting/TemplateManagement'),
    },
    // 模板管理 -- 编辑
    {
        path: '/smsEdit',
        name: 'smsEdit',
        meta: { parent, name: 'TemplateManagement' },
        component: () => import('@/views/MassTexting/TemplateManagement/edit.vue'),
    },
    // 模板管理 -- 详情
    {
        path: '/templateDetail',
        name: 'templateDetail',
        meta: { parent, name: 'TemplateManagement' },
        component: () => import('@/views/MassTexting/TemplateManagement/detail.vue'),
    },
    // 发送记录
    {
        path: '/sendingRecord',
        name: 'SendingRecord',
        meta: { parent, name: 'name' },
        component: () => import('@/views/MassTexting/SendingRecord/index'),
    },
];
