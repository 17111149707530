import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import config from './modules/config'
import applet from './modules/applet'
import getters from './getter'

Vue.use(Vuex);

export default new Vuex.Store({
  // strict: true,
  modules: {
    user,
    getters,
    applet,
    config
  },
})
