<template>
    <div class="z-search">
        <Dropdown
            v-if="filters.length > 1"
            @on-visible-change="visibleChange"
            @on-click="dropdownChange"
            class="c-top-select"
            size="small"
            placement="bottom-start"
        >
            <Button type="primary" class="search-button" style="display: flex;align-items: center;">
                <span :style="{width: btnTextWidth}">{{ filterLabel }}&nbsp;</span>
                <i :class="{'transform-icon': dropdownVisible}" class="pc-iconfont icon-down-xian"/>
            </Button>
            <DropdownMenu slot="list" placement="left-start">
                <DropdownItem v-for="row in filters" :name="row.value">{{ row.label }}</DropdownItem>
            </DropdownMenu>
        </Dropdown>
        <Button v-else type="primary" class="search-button"><span>{{ filterLabel }}</span></Button>

        <Input
            v-model="keywords"
            size="small"
            icon="md-search"
            class="c-top-search"
            :placeholder="`输入${filterLabel}搜索`"
            @on-click="onChange"
            @on-enter="onChange"
            :style="{ width: width }"
        />
    </div>
</template>

<script>
    import { getSearchFilters } from "@/viewComponents/ZSearch/data";

    export default {
        name: "index",
        props: {
            type: {
                type: String,
                default: '',
            },
            params: {
                type: Object,
                default: () => {
                    return {}
                },
            },
            width: {
                type: String,
                default: '180px',
            },
        },
        data() {
            return {
                dropdownVisible: false,
                watchDeepStatus: true,
                keywords: '',
                filterParams: {},
                filtersItem: {},
                filters: getSearchFilters(this.type),
            }
        },
        computed: {
            filterLabel() {
                return this.filtersItem.label;
            },
            filterValue() {
                return this.filtersItem.value;
            },
            btnTextWidth() {
                let _label = '';
                for (let row of this.filters) {
                    if (row.label.length > _label.length) {
                        _label = row.label;
                    }
                }
                return `${_label.length * 12 + 3}px`;
            }
        },
        watch: {
            params: {
                handler(newValue) {
                    if (this.watchDeepStatus) {
                        this.getKeywords(newValue);
                    }
                },
                immediate: true,
                deep: true,
            },
            type(newValue) {
	            this.resetFilterParams();
                this.filters = getSearchFilters(newValue);
                this.getKeywords(this.params);
            }
        },
        methods: {
            // 下拉框显示隐藏事件
            visibleChange(bool) {
                this.dropdownVisible = bool;
            },
            getKeywords(newValue) {
                this.keywords = '';
                let filterKey = '';
                for (let row of this.filters) {
                    if (newValue[row.value]) {
                        filterKey = row.value;
                        this.keywords = newValue[row.value];
                    }
                }
                if (!filterKey) {
                    filterKey = this.filters[0].value;
                }
                this.getFiltersItem(filterKey);
            },
            getFiltersItem(value) {
                for (let row of this.filters) {
                    if (value === row.value) {
                        this.filtersItem = row;
                    }
                }
            },
            dropdownChange(value) {
                if (this.filterValue === value) return;
                this.getFiltersItem(value);
                if (this.keywords) {
                    this.resetFilterParams();
                    this.keywords = '';
                    this.onChange();
                }
            },
            resetFilterParams() {
                for (let row of this.filters) {
                    this.filterParams[row.value] = '';
                }
            },
            onChange() {
                this.watchDeepStatus = false;
                this.filterParams[this.filterValue] = this.keywords;
                this.$emit('change', this.filterParams, this.filtersItem);
            }
        }
    }
</script>

<style scoped lang="scss">
    .z-search {
        display: flex;
        align-items: center;

        ::v-deep .ivu-btn.ivu-btn-primary {
            padding: 5px 10px 5px 12px;
            background-color: #017DFF;
            border-color: #017DFF;
        }

        ::v-deep .ivu-btn > span {
            display: flex;
            align-items: center;
            justify-content: center;
            vertical-align: middle;
            line-height: 13px;
        }
    }

    .search-button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        box-shadow: none;
    }

    ::v-deep .ivu-input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        box-shadow: none;
    }

    .transform-icon {
        transform: rotateZ(180deg);
    }
</style>