import request from '../server/axios';
import requestAi from '../server/ai-axios';

// v3统一使用这个接口
export const postV3Api = (method, params, config = {}) => {
    return request({
        url: `/v3/api?${method}`,
        method: 'post',
        data: {
            method,
            params,
        },
        ...config,
    });
};

// v3统一使用这个接口
export const postRestbV3Api = (method, params) => {
    return request({
        url: '/restb/v3/api',
        method: 'post',
        data: {
            method,
            params,
        },
    });
};

//AI对话接口
export const postV3Dialogue = (method, params) => {
    return requestAi({
        url: `/restb/v3/api/dialogue/${method}`,
        method: 'post',
        data: {
            ...params,
        },
    });
};

// 获取店铺列表
export const getMajorList = (params) => {
    params.mall_id = $.cookie('mall_id');
    return request({
        url: '/api/shop/major-list',
        method: 'get',
        params,
    });
};

// 获取用户信息
export const getUserInfo = (params) => {
    return request({
        url: '/api/user/info',
        method: 'get',
        params,
    });
};

// 获取菜单权限
export const getSystemMenu = (params = {}) => {
    return request({
        url: '/api/system/menu',
        method: 'get',
        params,
    });
};

// 角色权限
export const getSystemElement = (params = {}) => {
    return request({
        url: '/api/system/element',
        method: 'get',
        params,
    });
};

// 获取菜单权限
export const getFakeLogin = (params) => {
    return request({
        url: '/api/fake-login',
        method: 'get',
        params,
    });
};
