const parent = 'appletsmanages';
const _appletsManage = { parent, name: 'appletsmanage' };
const _name = { parent, name: 'name' };

export default [
    {
        path: '/appletsmanage',
        name: 'appletsmanage',
        meta: { ..._name, title: '小程序管理' },
        component: () => import('@/components/Applets/AppletsManage'),
    },
    {
        path: '/customNavigation',
        name: 'CustomNavigation',
        meta: { ..._appletsManage, title: '自定义导航栏' },
        component: () => import('@/views/CustomNavigation'),
    },
    {
        path: '/seachkeyword',
        name: 'seachkeyword',
        meta: { ..._appletsManage, title: '搜索关键字' },
        component: () => import('@/components/Applets/SeachKeyword'),
    },
    // 小程序主题管理
    {
        path: '/thememanage',
        name: 'thememanage',
        meta: _appletsManage,
        component: () => import('@/components/Applets/ThemeManage'),
    },
    {
        path: '/category',
        name: 'category',
        meta: _appletsManage,
        component: () => import('@/components/Applets/Category'),
    },
    // 轮播图管理
    {
        path: '/imagemanage',
        name: 'imagemanage',
        meta: _appletsManage,
        component: () => import('@/components/Applets/ImageManage'),
    },
    // 活动bar管理
    {
        path: '/barmanage',
        name: 'barmanage',
        meta: _appletsManage,
        component: () => import('@/components/Applets/BarManage'),
    },
    // 活动bar管理
    {
        path: '/modulemanage',
        name: 'modulemanage',
        meta: _appletsManage,
        component: () => import('@/components/Applets/ModuleManage'),
    },
    // 弹框管理
    {
        path: '/popmanage',
        name: 'popmanage',
        meta: _appletsManage,
        component: () => import('@/components/Applets/PopManage'),
    },
    {
        path: '/applyimagemanage',
        name: 'applyimagemanage',
        meta: { ..._appletsManage, title: '支付宝跳转' },
        component: () => import('@/components/Applyapplet/ApplyImageManage'),
    },
    {
        path: '/applybarmanage',
        name: 'applybarmanage',
        meta: { ..._appletsManage, title: '支付宝跳转' },
        component: () => import('@/components/Applyapplet/ApplyBarManage'),
    },
    {
        path: '/applycardmanage',
        name: 'applycardmanage',
        meta: { ..._appletsManage, title: '支付宝跳转' },
        component: () => import('@/components/Applyapplet/ApplyCardManage'),
    },
    {
        path: '/brandmanage', // 运费账单
        name: 'brandmanage',
        meta: { ..._name, title: '闪购品牌管理' },
        component: () => import('@/components/Applets/Brandmanage'),
    },
    {
        path: '/pointmanage',
        name: 'pointmanage',
        meta: { ..._name, title: '积分商品货架' },
        component: () => import('@/components/Applets/pointManage'),
    },
    {
        path: '/shelfmanage',
        name: 'shelfmanage',
        meta: { ..._name, title: '闪购商品管理' },
        component: () => import('@/components/Applets/ShelfManage'),
    },
    {
        path: '/groupmanage',
        name: 'groupmanage',
        meta: { ..._name, title: '拼团商品管理' },
        component: () => import('@/components/Applets/GroupManage'),
    },
    //有价券专区
    {
        path: '/valuablemanagement',
        name: 'valuablemanagement',
        meta: _name,
        component: () => import('@/components/Applets/ValuableManagement'),
    },
    // 优惠券上线管理
    {
        path: '/cardmanage',
        name: 'cardmanage',
        meta: _name,
        component: () => import('@/components/Applets/CardManage'),
    },
    {
        path: '/itemarea', // 闪购专区
        name: 'itemarea',
        meta: { ..._name, title: '闪购专区' },
        component: () => import('@/components/ItemArea/ItemArea'),
    },
    {
        path: '/itemareaadd', // 新增闪购专区
        name: 'itemareaadd',
        meta: { parent, name: 'itemarea', title: '新增闪购专区' },
        component: () => import('@/components/ItemArea/ItemAreaAdd'),
    },
    // 其他设置
    {
        path: '/otherSettings',
        name: 'otherSettings',
        meta: _name,
        component: () => import('@/components/Applets/otherSettings/index'),
    },
    // 支付页提示
    {
        path: '/paymentPrompt',
        name: 'paymentPrompt',
        meta: _name,
        component: () => import('@/components/Applets/otherSettings/paymentPrompt'),
    },
    // 隐私协议
    {
        path: '/privacyAgreement',
        name: 'privacyAgreement',
        meta: _name,
        component: () => import('@/components/Applets/otherSettings/privacyAgreement'),
    },
    // 商品购买情况显示
    {
        path: '/itemSellInfo',
        name: 'itemSellInfo',
        meta: _name,
        component: () => import('@/components/Applets/otherSettings/itemSellInfo'),
    },
    // 优惠券领取情况显示
    {
        path: '/couponReceiveInfo',
        name: 'couponReceiveInfo',
        meta: _name,
        component: () => import('@/components/Applets/otherSettings/couponReceiveInfo'),
    },
    // 无感积分开通提示
    {
        path: '/quickPointPrompt',
        name: 'quickPointPrompt',
        meta: _name,
        component: () => import('@/components/Applets/otherSettings/quickPointPrompt'),
    },
    {
        path: '/activityRegistrationManage',
        name: 'activityRegistrationManage',
        meta: { ..._name, title: '活动报名专区' },
        component: () => import('@/views/activityRegistrationManage/index'),
    },
    {
        path: '/loginSetting',
        name: 'loginSetting',
        meta: { ..._name, title: '登陆设置' },
        component: () => import('@/components/Applets/otherSettings/LoginSetting'),
    },
];
