const parent = 'market';
const couponMarket = { parent, name: parent };

export default [
    {
        path: "/market",
        name: "market",
        component: () => import('@/components/Game/Market'),
    },
    {
        path: "/game",
        name: "game",
        meta: couponMarket,
        component: () => import('@/components/Game/Game'),
    },
    {
        path: "/balldetail",
        name: "balldetail",
        meta: couponMarket,
        component: () => import('@/components/Game/BallDetail'),
    },
    {
        path: "/addball",
        name: "addball",
        meta: couponMarket,
        component: () => import('@/components/Game/AddBall'),
    },
    {
        path: "/gamereport",
        name: "gamereport",
        meta: couponMarket,
        component: () => import('@/components/Game/GameReport'),
    },
    {
        path: "/turntable",
        name: "turntable",
        meta: couponMarket,
        component: () => import('@/components/Game/TurnTable'),
    },
    {
        path: "/tabledetail",
        name: "tabledetail",
        meta: couponMarket,
        component: () => import('@/components/Game/TableDetail'),
    },
    // 拼颜值抽奖
    {
        path: "/newgamelist",
        name: "newgamelist",
        meta: { ...couponMarket, title: "游戏列表" },
        component: () => import('@/components/GameLotteryDraw/Game'),
    },
    {
        path: "/newgameadd",
        name: "newgameadd",
        meta: { ...couponMarket, title: "新增游戏" },
        component: () => import('@/components/GameLotteryDraw/GameAdd'),
    },
    {
        path: "/newgamedetail",
        name: "newgamedetail",
        meta: { ...couponMarket, title: "游戏详情" },
        component: () => import('@/components/GameLotteryDraw/GameDetail'),
    },
    {
        path: "/newgamereport",
        name: "newgamereport",
        meta: { ...couponMarket, title: "数据报表" },
        component: () => import('@/components/GameLotteryDraw/GameReport'),
    },
    // 好友助力
    {
        path: "/socialfission",
        name: "socialfission",
        meta: couponMarket,
        component: () => import('@/components/Game/SocialFission'),
    },
    {
        path: "/addfission",
        name: "addfission",
        meta: couponMarket,
        component: () => import('@/components/Game/SocialFission/addFission'),
    },
    {
        path: "/fissiondetail",
        name: "fissiondetail",
        meta: couponMarket,
        component: () => import('@/components/Game/SocialFission/fissionDetail'),
    },
    {
        path: "/fissionreport",
        name: "fissionreport",
        meta: couponMarket,
        component: () => import('@/components/Game/SocialFission/fissionReport'),
    },
    // 助力抽奖
    {
        path: "/assistance",
        name: "assistance",
        meta: { ...couponMarket, title: "助力抽奖" },
        component: () => import('@/components/Game/Assistance/Assistance'),
    },
    {
        path: "/assistanceAdd",
        name: "assistanceAdd",
        meta: { ...couponMarket, title: "新增助力抽奖" },
        component: () => import('@/components/Game/Assistance/AssistanceAdd'),

    },
    {
        path: "/assistanceReport",
        name: "assistanceReport",
        meta: { ...couponMarket, title: "新增助力抽奖报表" },
        component: () => import('@/components/Game/Assistance/AssistanceReport'),
    },
    {
        path: "/assistanceDetail",
        name: "assistanceDetail",
        meta: { ...couponMarket, title: "助力抽奖详情" },
        component: () => import('@/components/Game/Assistance/AssistanceDetail'),
    },
    // 邀请有礼
    {
        path: "/inviteregister",
        name: "inviteregister",
        meta: { ...couponMarket, title: "邀请有礼" },
        component: () => import('@/components/Game/InviteRegister'),
    },
    {
        path: "/inviteregisterdetail",
        name: "inviteregisterdetail",
        meta: { ...couponMarket, title: "邀请有礼" },
        component: () => import('@/components/Game/InviteRegister/InviteRegisterDetail'),
    },
    {
        path: "/inviteregisterreport",
        name: "inviteregisterreport",
        meta: { ...couponMarket, title: "邀请有礼报表" },
        component: () => import('@/components/Game/InviteRegister/InviteRegisterReport'),
    },
    {
        path: "/inviteregisterAdd",
        name: "inviteregisterrAdd",
        meta: { ...couponMarket, title: "邀请有礼新增" },
        component: () => import('@/components/Game/InviteRegister/InviteRegisterAdd'),
    },
    {
        path: "/smile",
        name: "smile",
        meta: couponMarket,
        component: () => import('@/components/Game/Smile'),
    },
    // 互动营销宝
    {
        path: "/interact",
        name: "interact",
        meta: { ...couponMarket, title: "互动营销宝" },
        component: () => import('@/components/Game/interact'),
    },
    {
        path: "/interactmanage",
        name: "interactmanage",
        meta: { ...couponMarket, title: "管理互动游戏" },
        component: () => import('@/components/Game/interact/interactManage'),
    },
    // 微笑有礼详情
    {
        path: "/smiledetail",
        name: "smiledetail",
        meta: couponMarket,
        component: () => import('@/components/Game/SmileDetail'),
    },
    {
        path: "/activityRegistration",
        name: "activityRegistration",
        meta: { ...couponMarket, title: '活动报名' },
        component: () => import("@/views/activityRegistration/index"),
    },
    {
        path: `/activityRegistration/detail`,
        name: "activityRegistrationDetail",
        meta: { ...couponMarket, title: '活动报名详情' },
        component: () => import("@/views/activityRegistration/detail"),
    }, {
        path: `/activityRegistration/edit`,
        name: "activityRegistrationEdit",
        meta: { ...couponMarket, title: '活动报名编辑' },
        component: () => import("@/views/activityRegistration/edit"),
    }, {
        path: `/activityRegistration/report`,
        name: "activityRegistrationReport",
        meta: { ...couponMarket, title: '活动报名报表' },
        component: () => import("@/views/activityRegistration/report"),
    },
]