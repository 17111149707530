import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import api from '@zhima/api';
import rest from '@zhima/api/rest.index';
import md5 from 'js-md5';
import './permission';
import './icons/index';
import './assets/css/common.css';
import './assets/css/pc_app.css';
import './styles/echarts.scss';
import './assets/iconfont/iconfont.css';
import './assets/js/jquery.cookie';
import './assets/iconfont/iconfont';

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import iView from 'iview';
import 'iview/dist/styles/iview.css';

import BaseComponents from './viewComponents/baseComponents';
import htmlToPdf from './components/Utils/htmlToPdf';
import directives from './utils/directives.js';
import Prototype from './utils/prototype';

Vue.use(BaseComponents);
Vue.use(htmlToPdf);
Vue.use(directives);
Vue.use(ElementUI);
Vue.use(iView);
Vue.use(Prototype);
Vue.prototype.$md5 = md5;
Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV === 'development';

api.updateConfig({
    headers: {
        'REQUEST-SYSTEM': 'MOLLI2',
    },
});
Vue.prototype.$api = api;
Vue.prototype.$rest = rest;
Vue.prototype.$echarts = echarts;

localStorage.removeItem('attribution');

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
