/* 公共函数存放
 *
 */

// 商场信息
export const defaultMallDetail = () => {
    return {
        name: $.cookie('mall_name'),
        address: $.cookie('mall_address'),
        logo: $.cookie('mall_logo'),
    };
};

// 数据转换 字典
export const dictTitle = (data, key, val) => {
    if (!val) {
        val = key;
        key = 'id';
    }
    let obj = {};
    for (let el of data) {
        if (String(el[key])) obj[el[key]] = el[val];
    }
    return obj;
};

// json 只显示第一个名称
export const showOneNamesUnit = (data, ids = [], unit = '家') => {
    let texts = [];
    let names = '';
    for (let item of data) {
        if (ids.length) {
            if (ids.includes(item.id)) {
                texts.push(item.name);
            }
        } else {
            texts.push(item.name);
        }
    }
    if (texts.length > 1) {
        names = texts[0] + ' 等' + texts.length + unit;
    } else {
        names = texts[0];
    }
    return names;
};

// json 取值冒号拼接
export const jsonChangeString = (el = [], key = 'name') => {
    let value = '';
    if (el.length) {
        let names = el.map((val) => val[key]);
        value = names.join('、');
    }
    return value;
};

// 值为 空 / undefined / null 且 不等于零
export const isEmptyNotZero = (value) => {
    return value !== 0 && !value;
};

// 开始时间 大于 结束时间 true
export const startThanEnd = (start, end) => {
    return !dateContrast(start, end, '<');
};

// 开始时间 大于等于 结束时间 true
export const startThanEqualEnd = (start, end) => {
    return !dateContrast(start, end);
};

// 开始时间 结束时间对比
export const dateContrast = (start, end, type) => {
    if (start.includes('-')) {
        let startDate = Date.parse(start.replace(/-/g, '/'));
        let endDate = Date.parse(end.replace(/-/g, '/'));
        return type === '<' ? startDate < endDate : startDate <= endDate;
    } else {
        let startTime = start.split(':').join('');
        let endTime = end.split(':').join('');
        return type === '<' ? startTime < endTime : startTime <= endTime;
    }
};

export const isEmojiCharacter = (substring) => {
    for (let i = 0; i < substring.length; i++) {
        let hs = substring.charCodeAt(i);
        if (0xd800 <= hs && hs <= 0xdbff) {
            if (substring.length > 1) {
                let ls = substring.charCodeAt(i + 1);
                let uc = (hs - 0xd800) * 0x400 + (ls - 0xdc00) + 0x10000;
                if (0x1d000 <= uc && uc <= 0x1f77f) {
                    return true;
                }
            }
        } else if (substring.length > 1) {
            let ls = substring.charCodeAt(i + 1);
            if (ls == 0x20e3) {
                return true;
            }
        } else {
            if (0x2100 <= hs && hs <= 0x27ff) {
                return true;
            } else if (0x2b05 <= hs && hs <= 0x2b07) {
                return true;
            } else if (0x2934 <= hs && hs <= 0x2935) {
                return true;
            } else if (0x3297 <= hs && hs <= 0x3299) {
                return true;
            } else if (hs == 0xa9 || hs == 0xae || hs == 0x303d || hs == 0x3030 || hs == 0x2b55 || hs == 0x2b1c || hs == 0x2b1b || hs == 0x2b50) {
                return true;
            }
        }
    }
};

// 秒 转换成 天时分
export const setLotteryPeriod = (value = 0, type) => {
    if (!Number(value)) value = 0;
    let value1 = parseInt(value / 1440) || '';
    let value2 = parseInt((value % 1440) / 60) || '';
    let value3 = (value % 1440) % 60 || '';
    if (type === 'unit') {
        return `${value1 ? value1 + '天' : ''} ${value1 || value2 ? value2 + '时' : ''} ${value1 || value2 || value3 ? value3 + '分' : ''}`;
    }
    return {
        value1,
        value2,
        value3,
    };
};

// 表单验证锚点跳转
export const promptScroll = (prompt, value, that) => {
    let _prompts = that.cloneDeepJson(prompt);
    let _value = that.cloneDeepJson(value);
    let { unit_key, scroll_key } = _value;
    for (let key in _prompts) {
        if (_prompts.hasOwnProperty(key)) {
            if (!_prompts[key]) {
                delete _prompts[key];
            }
        }
    }
    let prompt_key = '';
    for (let key of unit_key.reverse()) {
        if (_prompts[key]) prompt_key = key;
    }
    that.$el.querySelector(`#scroll${scroll_key[prompt_key]}`).scrollIntoView();
};

// 前端数据分页
export const setPageData = (data, num = 10) => {
    let value = [],
        total = Math.ceil(data.length / num);
    for (let j = 1; j < total + 1; j++) {
        value[j - 1] = [];
        for (let i = 0; i < num; i++) {
            let item = data[(j - 1) * num + i];
            if (item) {
                value[j - 1].push(item);
            }
        }
    }
    return value;
};

// 返回列表 记录表格筛选状态
export const tableFilterInit = (that, query, columns1) => {
    for (let i = 0, len = columns1.length; i < len; i++) {
        let item = columns1[i];
        if (item.sortable) {
            if (item.key === query.order_by || item.key === query.order) {
                that.$set(item, 'sortType', query.sort);
            } else {
                if (item.hasOwnProperty('sortType')) delete item.sortType;
            }
        }
        if (item.filters) {
            let key = item.filter_key ? item.filter_key : item.key;
            if (query[key] || query[key] === 0) {
                item.filteredValue = that.$set(item, 'filteredValue', [query[key]]);
            } else {
                if (item.hasOwnProperty('filteredValue')) delete item.filteredValue;
            }
        }
    }
};

export const defaultRoute = (val) => {
    if (!val) return;
    let status = val.children && val.children.length;
    if (!status) {
        return val.route;
    }
    if (status) {
        let findResult = defaultRoute(val.children[0]);
        if (findResult) {
            return findResult;
        }
    }
};

export const removeStorageCookie = () => {
    console.log('noRemoveCooks');
    // 需要保留的cookie
    const noRemoveCooks = ['default_route', 'company_manager', 'mall_logo', 'audit', 'support_pv', 'deployment_type', 'support_alipayapp', 'mall_address', 'before_mall3994'];
    // 删除cookie
    for (let el of document.cookie.split(';')) {
        let item = el.replace(/(^\s+)|(\s+$)/g, '');
        let value = item.split('=');
        // if (!noRemoveCooks.includes(value[0])) {
        $.removeCookie(value[0]);
        // }
    }
    const firstFiveTimes = localStorage.getItem('firstFiveTimes')
    localStorage.clear();
    localStorage.setItem('firstFiveTimes', firstFiveTimes);
    sessionStorage.clear();
};

/**
 * 计算种草部分图片显示比例
 *
 * type 图片来源 list：列表 detail：详情页
 * 当type为list时的scaleType值
 * @return {Number} 1: 宽高比<6/7，显示为长形缩略图，显示最短边
 * @return {Number} 2: 6/7≤宽高比，显示为方形缩略图，显示最短边
 *
 * 当type为detail时的scaleType值
 * @return {Number} 1: 宽高比<6/7，显示为3:4图，显示最短边
 * @return {Number} 2: 6/7≤宽高比<8/7，显示为方形缩略图，显示最短边
 * @return {Number} 3: 8/7≤宽高比，显示为4:3图，显示最短边
 */
export const handleRecommendImgScale = (width, height, type) => {
    let scaleType = 0, // 最终返回的显示类型
        scale = width / height; // 图片宽高比
    if (type == 'list') {
        scaleType = scale < 6 / 7 ? 1 : 2;
    } else if (type == 'detail') {
        if (scale < 6 / 7) {
            scaleType = 1;
        } else if (scale >= 6 / 7 && scale < 8 / 7) {
            scaleType = 2;
        } else if (scale >= 8 / 7) {
            scaleType = 3;
        }
    }
    return scaleType;
};
