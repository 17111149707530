import {postV3Api} from "@/api/api";
import OSS from 'ali-oss';

const initOss = async () => {
    const storeOss = await postV3Api('token.storeOss', {}).catch(error => error);
    if (storeOss.isError) {
        iview.Message.error('error: storeOss');
        return null;
    }
    if (storeOss.code !== 200) {
        iview.Message.error(storeOss.msg);
        return null;
    }
    const {data} = storeOss;
    const ossOption = {
        savePath: data.path,
        accessKeySecret: data.access_key_secret,
        stsToken: data.security_token,
        accessKeyId: data.access_key_id,
        refreshSTSToken: {
            accessKeyId: data.access_key_id,
            accessKeySecret: data.access_key_secret,
            stsToken: data.security_token,
        },
        host: data.host,
        region: data.region,
        bucket: data.bucket,
        refreshSTSTokenInterval: 300000,
    };
    return {
        ossOption,
        dockOss: new OSS(ossOption),
    };
};

export default initOss;