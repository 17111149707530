import router from './router'
import { loginSuccess } from './components/Login/data'
import {getFakeLogin, getUserInfo} from "@/api/api";
import { removeStorageCookie } from "@/utils/common";
import moment from 'moment'
import $http from "@/components/commen";
import Vue from 'vue';


window.addEventListener('error', (msg, url, row, col, error) => {
    if (msg.target && (msg.target.nodeName === 'SCRIPT') && !msg.message) {
        if (confirm('检测到平台程序有更新，是否需要刷新浏览器？')) {
            // console.error('可能您的浏览器还在使用过期的缓存文件，是否需要帮您自动刷新浏览器？' + '原因为找不到' + targetSrc);
            window.location.reload()
        }
    }
    // console.warn(msg.target ? (msg.target.outerHTML || msg.srcElement.outerHTML) : '无法找到目标地址');
    // console.warn(msg, url, row, col, error);
    return true;
}, true);

router.afterEach(async (to, from, next) => {
    $(".right-container.ivu-col.ivu-col-span-21").scrollTop(0);
    $(".right-container.ivu-col.ivu-col-span-24").scrollTop(0);

    if (to.path !== "/login" && to.path !== 'setNewPassword' && to.path !== '/findpass') { //领展定制--每365天至少修改一次密码
        let attribution = localStorage.getItem('attribution') // 1. 常规 2.领展 3.华联
        if (!attribution) {
            const res = await $http._send(`restful/mall/${$.cookie('mall_id')}/config`)
            localStorage.setItem('attribution', res.data.data?.custom?.attribution || 1)
            attribution = localStorage.getItem('attribution')
        }
        let password_changed_at = $.cookie('password_changed_at')?.split(' ')[0]
        if (!password_changed_at) {
            const info = await getUserInfo()
            password_changed_at = info.data.password_changed_at?.split(' ')[0]
        }
        if (attribution == 2 && password_changed_at) {
            const specifiedDate = moment(password_changed_at, 'YYYY-MM-DD');
            const currentDate = moment();
            const daysDiff = 365 + specifiedDate.diff(currentDate, 'days');
            const formatMoment = currentDate.format('YYYY-MM-DD')
            let times = 0
            const storeDate = localStorage.getItem('firstFiveTimes')?.split('*')[0]
            if(storeDate && storeDate===formatMoment) {
                times = Number(localStorage.getItem('firstFiveTimes')?.split('*')[1])
            }
            if (times < 5 && daysDiff > 0 && daysDiff <= 15) {
                Vue.prototype.$Message.warning(`您有较长时间没有更新密码，请在${daysDiff}天内更新密码，否则账号将被禁用`);
                // alert(`您有较长时间没有更新密码，请在${daysDiff}天内更新密码，否则账号将被禁用`);
                times++
                localStorage.setItem('firstFiveTimes', formatMoment + '*' + times);
            }
        }
    }
});

router.beforeEach(async (to, from, next) => {
    let access_token = $.cookie("access_token");
    let uid = $.cookie("winshang_uid");
    if (to.query.signature) {
        removeStorageCookie();
        let query = to.query;
        let params = {
            timestamp: query.timestamp,
            signature: query.signature,
            source: query.source
        };
        getFakeLogin(params).then(data => {
            loginSuccess(data);
        });
    }
    localStorage.setItem('simulationLogin', !to.query.signature ? 2 : 1); // 是否为模拟登录 1:否 2:是（用于判断是否开启超管编辑权限）
    if (access_token && !uid) {
        $.removeCookie("access_token");
        return next({
            path: "/login",
        });
    }
    if (!access_token && to.path !== "/login" && to.path !== "/findpass") {
        return next({
            path: "/login",
        });
    }
    if ((access_token && to.path == "/login") || (access_token && (!to.name || to.path == "/"))) {
        if (from.name) {
            return next({
                path: from.path
            });
        } else {
            if ($.cookie("default_route")) {
                return next({
                    name: $.cookie("default_route")
                });
            }
        }
    }
    next();
});