const parent = 'pointshop';

export default [
    //积分商城
    {
        path: "/itemlist",
        name: "itemlist",
        meta: { parent, name: 'name' },
        component: () => import('@/components/PointMall/ItemList'),
    },
    {
        path: "/itemdetail",
        name: "itemdetail",
        meta: { parent, name: 'itemlist' },
        component: () => import('@/components/PointMall/ItemDetail'),
    },
    {
        path: "/itemadd",
        name: "itemadd",
        meta: { parent, name: 'itemlist' },
        component: () => import('@/components/PointMall/ItemAdd'),
    },
    {
        path: "/itemreport",
        name: "itemreport",
        meta: { parent, name: 'itemlist' },
        component: () => import('@/views/PointItem/report'),
    },
    {
        path: "/orderlist",
        name: "orderlist",
        meta: { parent, name: 'name' },
        component: () => import('@/components/PointMall/OrderList'),
    },
    {
        path: "/orderdetail",
        name: "orderdetail",
        meta: { parent, name: 'orderlist' },
        component: () => import('@/components/PointMall/OrderDetail'),
    },
];