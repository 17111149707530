const parent = 'role';
const _parent = { parent, name: 'parent'};
const _name = { parent, name: 'name'};

export default [
    // 设备管理
    {
        path: "/equipment",
        name: "equipment",
        component: () => import('@/components/Config/Equipment'),
    },
    // 区域管理
    {
        path: "/regionalmanage",
        name: "regionalmanage",
        component: () => import('@/components/Config/RegionalManage'),
    },
    // 交易数据管理
    {
        path: "/shopdeal",
        name: "shopdeal",
        component: () => import("@/components/Config/ShopDeal"),
    },
    // 参数设置
    {
        path: "/paramset",
        name: "paramset",
        component: () => import("@/components/Config/Paramset"),
    },
    // // 子账号设置
    // {
    //     path: "/subaccountset",
    //     name: "subaccountset",
    //     component: () => import("@/components/Config/SubAccountset"),
    // },
    // // 编辑子账号
    // {
    //     path: "/editaccount",
    //     name: "editaccount",
    //     meta: { parent: 'subaccountset', name: 'subaccountset' },
    //     component: () => import("@/components/Config/EditAccount"),
    // },
    // 用户设置
    {
        path: "/userset",
        name: "userset",
        meta: { parent: 'roleset', name: 'name' },
        component: () => import("@/components/Role/Userset"),
    },
    // 短信接收人设置
    {
        path: "/receiver",
        name: "receiver",
        component: () => import("@/components/Role/Receiver"),
    },
    // 短信发送记录
    {
        path: "/messagerecord",
        name: "messagerecord",
        meta: { parent: 'receiver', name: 'name' },
        component: () => import("@/components/Role/MessageRecord"),
    },
]