import html2Canvas from 'html2canvas'
// import JsPDF from 'jspdf'
export default {
    install(Vue, options) {
        Vue.prototype.deepClone = function (val) {
            return JSON.parse(JSON.stringify(val));
        };
        Vue.prototype.getPdf = function () {
            let title = this.htmlTitle;
            html2Canvas(document.querySelector('#pdfDom'), {
                backgroundColor: '#14192d',
                allowTaint: true,
                useCORS: true
            }).then(function (canvas) {
                    let link = document.createElement('a');
                    let imgData = canvas.toDataURL('image/png', 0.5);
                    let arr = imgData.split(','), mime = arr[0].match(/:(.*?);/)[1],
                        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
                    while (n--) {
                        u8arr[n] = bstr.charCodeAt(n);
                    }
                    let blob = new Blob([u8arr], {type: mime});
                    link.href = URL.createObjectURL(blob);
                    link.setAttribute('download', title + '.png');
                    link.style.display = 'none';
                    document.body.appendChild(link);
                    link.click();
                }
            )
        };
        Vue.prototype.hrefUpload = function (href) {
            let link = document.createElement('a');
            link.href = href;
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
        };
        // 缓存页面参数 query
        Vue.prototype.$queryStorage = function (query) {
            if (query) {
                // 缓存 query
                sessionStorage.setItem('query_storage', JSON.stringify(query));
            } else {
                // 获取
                return new Promise(resolve => {
                    let query = {};
                    if (sessionStorage.getItem('query_storage')) {
                        query = JSON.parse(sessionStorage.getItem('query_storage'));
                    }
                    sessionStorage.removeItem('query_storage');
                    resolve(query);
                })
            }
        };
        // 重置页面 缓存
        Vue.prototype.$onbeforeunload = function (value = '', achieve) {
            window.onbeforeunload = e => {
                this.$setKind();
                this.$setPage(1);
                this.$setKeywords();
            };
        };
        // 重置页面 缓存
        Vue.prototype.$resetSession = function () {
            this.$setKind();
            this.$setPage(1);
            this.$setKeywords();
        };
        // 缓存分页
        Vue.prototype.$setPage = function (page) {
            if (!page) {
                return JSON.parse(sessionStorage.getItem('listPage')) || 1;
            } else {
                page = page === 'null' ? null : page;
                sessionStorage.setItem('listPage', JSON.stringify(page));
            }
        };
        // 缓存模糊搜索
        Vue.prototype.$setKeywords = function (value = '', achieve) {
            if (achieve) {
                return sessionStorage.getItem('sessionKeyword') || '';
            } else {
                window.sessionStorage.setItem('sessionKeyword', value);
            }
        };
        // 缓存头部切换
        Vue.prototype.$setKind = function (value = '', achieve) {
            if (achieve) {
                return sessionStorage.getItem('sessionKind') || '';
            } else {
                window.sessionStorage.setItem('sessionKind', value);
            }
        };
        Vue.prototype.$exportData = function (data, status) {
            console.log(data, status)
            let url = '';
            if (status === 'url') {
                url = data;
            } else {
                url = window.URL.createObjectURL(new Blob([data]))
            }
            let link = document.createElement('a');
            link.style.display = 'a';
            link.href = url;
            link.setAttribute('download', 'excel.xls');
            document.body.appendChild(link);
            link.click();
        };
        Vue.prototype.$dateContrast = function (start, end, type) {
            if (start.includes('-')) {
                let startDate = Date.parse(start.replace(/-/g, "/"));
                let endDate = Date.parse(end.replace(/-/g, "/"));
                if (type === '<') {
                    return startDate < endDate;
                } else {
                    return startDate <= endDate;
                }
            } else {
                let startTime = start.split(':').join('');
                let endTime = end.split(':').join('');
                if (type === '<') {
                    return startTime < endTime
                } else {
                    return startTime <= endTime
                }
            }
        };
    },
}
