import store from '../store/index'

export default (Vue) => {
    Vue.directive("dragscroll", {
        bind(el, binding) {
            el.onmousedown = ev => {
                const disX = ev.clientX;
                const disY = ev.clientY; // 需要上下移动可以加
                const originalScrollLeft = el.scrollLeft;
                const originalScrollTop = el.scrollTop; // 需要上下移动可以加
                const originalScrollBehavior = el.style['scroll-behavior'];
                const originalPointerEvents = el.style['pointer-events'];
                el.style['scroll-behavior'] = 'auto';
                // 鼠标移动事件是监听的整个document，这样可以使鼠标能够在元素外部移动的时候也能实现拖动
                document.onmousemove = dv => {
                    dv.preventDefault();
                    const distanceX = dv.clientX - disX;
                    const distanceY = dv.clientY - disY; // 需要上下移动可以加
                    el.scrollTo(originalScrollLeft - distanceX, originalScrollTop - distanceY);   // 需要上下移动和左右移动
                    // el.scrollTo(originalScrollLeft - distanceX, 0); // 不需要上下拖拽，y轴值为0
                };
                document.onmouseup = () => {
                    document.onmousemove = null;
                    document.onmouseup = null;
                    el.style['scroll-behavior'] = originalScrollBehavior;
                    el.style['pointer-events'] = originalPointerEvents;
                };
            };
        },
    });
    Vue.directive("numberOnly", {
        bind(el, binding) {
            el.onkeypress = (e) => {
                e = e || window.event;
                let config = binding.value || {};
                let charCode = typeof e.charCode == 'number' ? e.charCode : e.keyCode;
                let re = /\d|\./;
                if (!re.test(String.fromCharCode(charCode))) {
                    if (e.preventDefault()) {
                        e.preventDefault();
                    } else {
                        e.returnValue = false;
                    }
                }
                if (el.value || el.value === 0) {
                    el.value = pointNum(el.value, config.point, config.nonzero);
                } else {
                    let num = e.target.value;
                    e.target.value = num || num === 0 ? pointNum(num, config.point, config.nonzero) : '';
                }
            };
            el.addEventListener('input', el.onkeypress)
        },
        unbind: function (el) {
            el.removeEventListener('input', el.onkeypress)
        }
    });
    
    // 标签权限指令 是否显示标签
    Vue.directive('permission', {
        // 绑定时调用
        bind(el, binding) {
            handlePermission(el, binding)
        },
        update(el, binding) {
            handlePermission(el, binding)
        },
    });
    Vue.directive('resize', {
        bind(el, binding) { // el为绑定的元素，binding为绑定给指令的对象
            let width = '', height = '';
            function isResize() {
                const style = document.defaultView.getComputedStyle(el);
                if (width !== style.width || height !== style.height) {
                    binding.value({ width: style.width, height: style.height });  // 关键
                }
                width = style.width;
                height = style.height;
            }
            el.__vueSetInterval__ = setInterval(isResize, 300);
        },
        unbind(el) {
            clearInterval(el.__vueSetInterval__);
        }
    });
}

function handlePermission(el, binding) {
    const { value } = binding;
    // 在登录后就可以缓存当前用户的btn权限。
    // 自定义指令的执行函数，，取到登录缓存的按钮权限数组，赋值给全局对象userMsg
    let buttonPermissions = store.state.config.buttonPermissions;
    if (buttonPermissions && buttonPermissions.length && !buttonPermissions.includes(value)) {
        el.parentNode && el.parentNode.removeChild(el);
    }
}

/**
 * @param value {string}  input 值
 * @param point {number}  可输入的小数位数
 * @param nonzero {number}  正整数
 */
export function pointNum(value, point = 0, nonzero = 0) {
    value = String(value);
    value = value.replace(/[^\d.]/g, ''); //清除"数字"和"."以外的字符
    value = value.replace(/^\./g, ''); //验证第一个字符是数字
    value = value.replace(/\.{2,}/g, '.'); //只保留第一个, 清除多余的
    value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
    if (point === 1) {
        // eslint-disable-next-line no-useless-escape
        value = value.replace(/^(\-)*(\d+)\.(\d).*$/, '$1$2.$3'); //只能输入一位小数
    } else if (point === 2) {
        // eslint-disable-next-line no-useless-escape
        value = value.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3'); //只能输入两位小数
    } else {
        // eslint-disable-next-line no-useless-escape
        value = value.replace(/^(\-)*(\d+)\.*$/, '$1$2'); //不支持小数
    }
    if (value.indexOf('.') < 0 && value !== '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        value = parseFloat(value);
    }
    if (point === 0 && nonzero === 1) {
        if ((!value) || value === '0' || value === '0.0' || value === '0.00') {
            return '';
        }
    }
    return value;
}
