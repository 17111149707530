<template>
	<div>
		<el-breadcrumb>
			<el-breadcrumb-item
				v-for="(item, index) in breadList"
				:key="index"
				:to="{ path: getPath(item.path) }"
			>
				{{ digestParams(item.meta.title, $route.params) }}
			</el-breadcrumb-item>
		</el-breadcrumb>
	</div>
</template>

<script>
export default {
	name: "index",
	data() {
		return {
			breadList: [],
		}
	},

	watch: {
		$route(val) {
			this.getBreadList(val);
		},
	},
	mounted() {
		console.log(this.$route)
		this.getBreadList(this.$route);
	},
	methods: {
		getBreadList(val) {
			console.log(val.path,val.path.split('/').filter(item=>item))
			console.log()
			if (val.matched) {
				let matched = val.matched.filter(
					(item) => item.meta && item.meta.title
				);
				this.breadList = matched;
				console.log(this.breadList)
			}
		},
		getPath(path) {
			if (path === "") {
				return "/";
			}
			return this.digestParams(path, this.$route.params);
		},
		digestParams(breadcrumbName, params) {
			if (!breadcrumbName) {
				return null;
			}
			var paramsKeys = Object.keys(params).join("|");
			return breadcrumbName.replace(
				new RegExp(":(".concat(paramsKeys, ")"), "g"),
				function (replacement, key) {
					return params[key] || replacement;
				}
			);
		},
	},
}
</script>

<style scoped>

</style>