<template>
    <div class="component-filter-time">
        <Dropdown
            v-if="pickerList.length > 1"
            @on-visible-change="visibleChange"
            @on-click="dropdownChange"
            class="c-top-select"
            size="small"
            placement="bottom-start"
        >
            <Button type="primary">
                <span class="time-title" :style="{width: titleWidthValue}">{{ timeTitle }}&nbsp;</span>
                <i :class="{'transform-icon': visible}" class="pc-iconfont icon-down-xian"/>
            </Button>
            <DropdownMenu slot="list" placement="left-start">
                <DropdownItem v-for="row in pickerList" :name="row.value">{{ row.label }}</DropdownItem>
            </DropdownMenu>
        </Dropdown>
        <Button v-else type="primary"><span class="time-title">{{ timeTitle }}</span></Button>
        <el-date-picker
            :style="{width: pickerWidth}"
            class="c-input"
            size="mini"
            v-model="searchDates"
            :type="type"
            popper-class="blue-datepicker"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :editable="false"
            :format="pickerFormat"
            :value-format="pickerFormat"
            @change="changeDates"
        />
    </div>
</template>

<script>
    import { filterTimeList } from './data'

    export default {
        name: "index",
        props: {
            option: {
                type: String,
                default: ''
            },
            oneYear: {
                type: Boolean,
                default: false
            },
            filter: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            date: {
                type: Array,
                default: () => {
                    return []
                }
            },
            timeWidth: {
                type: String,
                default: ''
            },
            titleWidth: {
                type: String,
                default: ''
            },
            type: {
                type: String,
                default: 'daterange'
            },
            format: {
                type: String,
                default: ''
            },
            // 时间 返回到 时或分或秒
            joinTime: {
                type: [String, Number],
                default: 19
            },
        },
        data() {
            return {
                searchDates: [],
                storeSearchDates: [],
                visible: false,
                pickerList: [],
                listHash: {},
                timeTitle: '',
                timeValue: '',
                pickerActiveIndex: 0,
            }
        },
        computed: {
            // 日期筛选框宽度 不包括前端选项部分
            pickerWidth() {
                if (this.timeWidth) {
                    return this.timeWidth;
                } else {
                    if (this.type === 'datetimerange') {
                        return '320px'
                    } else {
                        return '210px'
                    }
                }
            },
            titleWidthValue() {
                if (this.titleWidth) {
                    return this.titleWidth;
                } else {
                    let _width = '77px'
                    for (let row of this.pickerList) {
                        if (row.label.length > 6) {
                            _width = '84px';
                        }
                    }
                    return _width;
                }
            },
            // 日期格式
            pickerFormat() {
                if (this.format) {
                    return this.format;
                } else {
                    if (this.type === 'datetimerange') {
                        return 'yyyy-MM-dd HH:mm:ss'
                    } else {
                        return 'yyyy-MM-dd'
                    }
                }
            },
        },
        watch: {
			option: {
				handler(val) {
					let _list = filterTimeList(val);
					let _hash = {};
					// 添加 key 生成字典
					for (let row of _list) {
						row.value = row.keys[0];
						_hash[row.value] = row;
					}
					this.pickerList = _list;
					this.listHash = _hash;
					this.getTimeValue(_list[0].value);
					
					// promise 之后触发
					setTimeout(_ => {
						// 获取 显示 key
						for (let row of _list) {
							if (this.filter[row.value]) {
								this.getTimeValue(row.value);
								for (let key of row.keys) {
									let _date = this.filter[key];
									this.searchDates.push(_date.substring(0, this.pickerFormat.length));
								}
							}
						}
					},1000) //防止filter值没有更新
				},
				immediate: true
			},
            filter: {
                handler(newVal) {
                    if (!Object.values(newVal).some(val => !!val)) {
                        this.searchDates = [];
                    }
                },
                deep: true
            },
        },
        mounted() {
        
        },
        methods: {
            // 下拉框显示隐藏事件
            visibleChange(bool) {
                this.visible = bool;
            },
            // 下拉框 选中
            dropdownChange(key) {
                this.pickerActiveIndex = this.pickerList.findIndex(row => row.value === key);
                this.getTimeValue(key);
                if (!this.searchDates.length) return;
                this.searchDates = [];
                this.onChange();
            },
            // 下拉框获取对应key数据
            getTimeValue(key) {
                let _label = this.listHash[key].label;
                if (_label.length > 6 && this.pickerList.length > 1) {
                    this.timeTitle = _label.substring(0, 6) + '...';
                } else {
                    this.timeTitle = _label;
                }
                this.timeValue = key;
            },
            // 选择日期
            changeDates(date) {
                if (!date) this.searchDates = [];
                this.onChange();
            },
            onChange() {
                let _list = this.cloneDeepJson(this.pickerList);
                let params = {};
                let valueKeys = [];
                for (let row of _list) {
                    if (row.value === this.timeValue) {
                        valueKeys = row.keys;
                        for (let i = 0; i < row.keys.length; i++) {
                            params[row.keys[i]] = this.searchDates[i] || '';
                        }
                    } else {
                        for (let key of row.keys) {
                            params[key] = '';
                        }
                    }
                }
                if (this.joinTime) {
                    const sliceTime = {
                        hh: 13,
                        mm: 16,
                        ss: 19,
                    };
                    let _dates = [];
                    for (let i = 0; i < valueKeys.length; i++) {
                        let _key = valueKeys[i];
                        if (params[_key]) {
                            if (i === 0) {
                                _dates.push(params[_key]);
                                params[_key] = `${params[_key]} 00:00:00`
                            }
                            if (i === 1) {
                                _dates.push(params[_key]);
                                params[_key] = `${params[_key]} 23:59:59`
                            }

                            params[_key] = params[_key].slice(0, sliceTime[this.joinTime])
                        }
                    }
                    // (暂未使用)
                    if (_dates.length && this.oneYear) {
                        let startTime = new Date(_dates[0]);
                        let startTimeNextYear = startTime.setFullYear((new Date(startTime).getFullYear() + 1)) - 1000 // 一年
                        let endTime = Date.parse(_dates[1].replace(/-/g, '/'));
                        if (endTime > startTimeNextYear) {
                            this.$Message.destroy();
                            this.$Message.error('时间区间不能超过一年，请重新选择');
                            this.searchDates = this.cloneDeepJson(this.storeSearchDates);
                            return;
                        }
                    }
                }
                this.storeSearchDates = this.cloneDeepJson(this.searchDates);
                this.$emit('change', params, valueKeys, this.pickerActiveIndex, this.pickerList[this.pickerActiveIndex]);
            },
        }
    }
</script>

<style scoped lang="scss">
    .component-filter-time {
        display: flex;
        align-items: center;

        ::v-deep .ivu-btn.ivu-btn-primary {
            padding: 5px 10px 5px 12px;
            background-color: #017DFF;
            border-color: #017DFF;
        }

        .c-input {
            padding: 3px 20px 3px 16px;

            ::v-deep .el-range-separator {
                display: inline-block;
                color: #FFFFFF;
                padding: 0;
                line-height: 16px;
            }
        }

        ::v-deep .el-range-editor--mini .el-range__close-icon {
            line-height: 18px;
        }

        ::v-deep .el-input__icon {
            transition: none;
        }

        ::v-deep .el-input__inner {
            border-radius: 0 4px 4px 0;
        }

        ::v-deep .ivu-btn > span {
            display: flex;
            align-items: center;
            justify-content: center;
            vertical-align: middle;
            line-height: 13px;
        }

        ::v-deep .ivu-btn.ivu-btn-primary {
            border-radius: 4px 0 0 4px;
            box-shadow: none;
        }
    }

    .time-title {
        width: auto;
        text-align: center;
    }

    .icon-down-xian {
        transition: all 0.3s;
    }

    .transform-icon {
        transform: rotateZ(180deg);
    }
</style>