export default [
    {
        path: '/marketingDataOld',
        name: 'MarketingDataOld',
        component: () => import('@/views/MarketingData'),
    },
    {
        path: '/groupMarketingDataOld',
        name: 'GroupMarketingDataOld',
        component: () => import('@/views/MarketingData'),
    },
    {
        path: '/memberDataOld',
        name: 'MemberDataOld',
        component: () => import('@/components/MemberData'),
    },
    {
        path: '/groupMemberDataOld',
        name: 'GroupMemberDataOld',
        component: () => import('@/components/MemberData'),
    },

    {
        path: '/marketingData',
        name: 'marketingdata',
        component: () => import('@/views/BiAnalysis/overviewData'),
        meta: { title: '数据概览' },
    },
    {
        path: '/groupMarketingData',
        name: 'groupmarketingdata',
        component: () => import('@/views/BiAnalysis/overviewData'),
        meta: { title: '数据概览' },
    },
    {
        path: '/memberData',
        name: 'memberdata',
        component: () => import('@/views/BiAnalysis/memberData'),
        meta: { title: '会员与访问数据' },
    },
    {
        path: '/groupmemberdata',
        name: 'groupmemberdata',
        component: () => import('@/views/BiAnalysis/memberData'),
        meta: { title: '会员与访问数据' },
    },
    {
        path: '/biInteractData',
        name: 'BiInteractData',
        component: () => import('@/views/BiAnalysis/interactData'),
        meta: { title: '互动数据' },
    },
    {
        path: '/biOrderData',
        name: 'biOrderData',
        component: () => import('@/views/BiAnalysis/orderData'),
        meta: { title: '订单数据' },
    },
    {
        path: '/biPointsData',
        name: 'BiPointsData',
        component: () => import('@/views/BiAnalysis/pointsData'),
        meta: { title: '积分数据' },
    },
    {
        path: '/biMaterialData',
        name: 'biMaterialData',
        component: () => import('@/views/BiAnalysis/materialData'),
        meta: { title: '营销素材数据' },
    },
    {
        path: '/biIndustriesStores',
        name: 'biIndustriesStores',
        component: () => import('@/views/BiAnalysis/industriesStores'),
        meta: { title: '行业与店铺数据' },
    },
    {
        path: '/biParkData',
        name: 'biParkData',
        component: () => import('@/views/BiAnalysis/parkData'),
        meta: { title: '停车数据' },
    },
    {
        path: '/biGroupRank',
        name: 'biGroupRank',
        component: () => import('@/views/BiAnalysis/groupRank/'),
        meta: { title: '集团对比排名' },
    },
];
