<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
import C from './components/commen/index';
import {deepCopy} from "@/utils/valid";
import Vue from 'vue';

export default {
    name: 'App',
    data() {
        return {
            viewKey: 'key',
        };
    },
    watch: {
        $route: 'setViewKey',
    },
    async created() {
		this.rewriteNewMessage()
        // let result = await this.$api.getMall({ mall_id: 2292 });
        // console.log("同步");
        // console.log(result);
        // this.$api.getMall({ mall_id: 2292 }).then(res => {
        //   console.log("异步");
        //   console.log(res);
        // });
        this.$store.commit('getMallInfoId', $.cookie('mall_id'));
        let data_source = $.cookie('data_source');
        if ($.cookie('mall_id')) {
            // this.$store.dispatch('getMallConfig');
            try {
                const resp = await C._send('/restful/mall/' + $.cookie('mall_id'));
                if (resp.data.code == 200) {
                    const _data = resp.data.data;
                    data_source = _data.data_source;

                    $.cookie('data_source', data_source);
                    $.cookie('support_miniapp', _data.support_miniapp);
                    $.cookie('support_pv', _data.support_pv);
                    $.cookie('data_source', data_source);
                    $.cookie('deployment_type', _data.deployment_type);
                    $.cookie('support_alipayapp', _data.support_alipayapp);
                }
            } catch (error) {
                C.httpStatusAction(error);
            }
        }
        if ($.cookie('mall_id')) this.getPointRule();
    },
    methods: {
        // 获取积分规则
        getPointRule() {
            sessionStorage.setItem('points_deduction', null);
            this.$api.getMallPoint_rule({ mall_id: $.cookie('mall_id') }).then((res) => {
                let data = res.data;
                sessionStorage.setItem('points_deduction', data.id ? data.points_deduction : 0);
            });
        },
        // 同一地址不同路由，跳转页面不刷新
        setViewKey(from, to) {
            if (from && from.matched[1] && to && to.matched[1]) {
                if (from.matched[1].components.default.__file === to.matched[1].components.default.__file) {
                    this.viewKey = to.name + new Date();
                }
            }
        },
	    rewriteNewMessage() {
		    const message = deepCopy(Vue.prototype.$Message)
		    Vue.prototype.$Message = {
			    ...message,
			    warning: function (val) {
				    if(val !== 'blacklistTips') {
					    message.warning(val)
				    }
			    },
			    error: function (val) {
				    if (val !== 'blacklistTips') {
					    message.error(val)
				    }
			    }
		    }
	    }
    },
};
</script>

<style lang="scss">
@import './styles/indexGlobal.scss';
#app {
    font-family: 'PingFangSC-Semibold', 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #ffffff;
    overflow-y: hidden;
}

#app::-webkit-scrollbar {
    margin-top: 10px;
    margin-right: 5px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 10px;
}

/*滚动条方块*/
#app::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background: #0dfdf2;
}
</style>
