export default {
    install(Vue) {
        // 缓存页面参数 query===================
        Vue.prototype.$saveQueryStorage = function (query, key = 'query_storage') {
            if (query) sessionStorage.setItem(key, JSON.stringify(query));
        };
        // 获取并且删除 缓存页面参数 query
        Vue.prototype.$getQueryStorage = function (key = 'query_storage') {
            return new Promise(resolve => {
                let query = {};
                if (sessionStorage.getItem(key)) {
                    query = JSON.parse(sessionStorage.getItem(key));
                }
                this.$removeQueryStorage(key);
                resolve(query);
            });
        };
        // 删除 缓存页面参数 query
        Vue.prototype.$removeQueryStorage = function (key = 'query_storage') {
            sessionStorage.removeItem(key);
        };
        // url下载===================
        Vue.prototype.hrefUpload = function (href) {
            let link = document.createElement('a');
            link.href = href;
            link.style.display = 'none';
            document.body.appendChild(link);
            link.click();
        };
        // 深拷贝===================
        // ES6 JSON拷贝
        // 无法拷贝函数
        // 无法拷贝copyObj对象原型链上的属性和方法
        // 当数据的层次很深，会栈溢出
        Vue.prototype.cloneDeepJson = function (val) {
            return JSON.parse(JSON.stringify(val));
        };
        // 不会栈溢出
        // 支持很多层级的数据
        Vue.prototype.cloneDeepLoop = function (val) {
            const root = {};
            // 栈
            const loopList = [
                {
                    parent: root,
                    key: undefined,
                    data: val,
                }
            ];

            while (loopList.length) {
                // 深度优先
                const node = loopList.pop();
                const parent = node.parent;
                const key = node.key;
                const data = node.data;

                // 初始化赋值目标，key为undefined则拷贝到父元素，否则拷贝到子元素
                let res = parent;
                if (typeof key !== 'undefined') {
                    res = parent[key] = {};
                }
                for (let k in data) {
                    if (data.hasOwnProperty(k)) {
                        if (typeof data[k] === 'object') {
                            // 下一次循环
                            loopList.push({
                                parent: res,
                                key: k,
                                data: data[k],
                            });
                        } else {
                            res[k] = data[k];
                        }
                    }
                }
            }
            return root;
        }
        // ===================
    },
};
