import axios from "axios";
import router from "../router/index";

const service = axios.create({
    // baseURL: process.env.VUE_APP_BASE_API,
    timeout: 60000,
});

let cancelApiArr = [];
// 请求拦截（配置发送请求的信息）
service.interceptors.request.use(
    (config) => {
        // const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxMzU3NzQ2NiIsImd1YXJkIjoibWVtYmVyIiwibWFsbF9pZCI6IjIzNzkiLCJpc3MiOiJodHRwczovL3Rlc3RhLnpoaW1hdGVjaC5jb20vcmVzdGZ1bC9tZW1iZXIveC90b2tlbiIsImV4cCI6NDA5MjU5OTM0OSwiaWF0IjoxNzI1OTM1ODEzLCJhcHBfaWQiOiJ3eDdhMjdjOGU4YWZkNzQxYTUifQ.PIV6pLXWfUyumuQYbqS_3HodClQn_Vj0R433YhibdFc';
        const token = $.cookie("ai_mock_token");
        config.headers["Authorization"] = `Bearer ${token}` || "";
        cancelApiArr = cancelApiArr.filter((row) => {
            // 自带怎么判断是同一个接口在重复请求  遍历这个数组如果请求方式和url一样就是同一个接口
            if (row.url === config.url && row.method === config.method && row.noReport === config.noReport) {
                // 确定是同一个接口调用取消方法
                row.fn()
                // 销毁数据 不然这个数组会越来越大
                // 符合这个要求的接口 调用取消axios方法后 不保留
                return false
            }
            // 不符合这个要求的接口 保留接口
            return true
        })
        if (config.noReport) {
            config.cancelToken = new axios.CancelToken(cancelFn => {
                // config 把地址和方式存进数组中 方便后面判断 所有方法存进数组中
                cancelApiArr.push({
                    url: config.url,
                    noReport: config.noReport,
                    method: config.method,
                    fn: cancelFn
                })
            });
        }
        return Promise.resolve(config);
    },
    (error) => {
        console.log('request error', error);
        return Promise.reject(error);
    }
);

// 响应拦截（配置请求回来的信息）
service.interceptors.response.use(
    (response) => {
        if (response.data.code === 40101){ //初始密码需要修改
             router.push({
                 path:'/setNewPassword',
                 query: {
                     origin: true
                 }
             })

        } else if (response.data.code === 40102){ //强制需要修改密码
            router.push({
                path:'/setNewPassword',
            })
        }
        return response.data;
    },
    (error) => {
        console.info('response error', error);
        let err = error.response;
        // 处理响应失败
        if (!err) {
            return Promise.reject(error);
        } else if (err.status === 401) {
            $.removeCookie('access_token');
            router.replace('/login');
            iview.Message.error('登录超时，请重新登录！');
        } else if (err.status === 500) {
            iview.Message.error('服务器出错了！');
        } else if (err.status === 504) {
            iview.Message.error('网络链接超时！');
        } else if (err.status === 406) {
            iview.Message.error(err.data.msg);
        } else {
            iview.Message.error(err.status);
        }
        return Promise.reject(error.response);
    }
);
export default service;
