const parent = 'pointWarningDetail';

export default [
    {
        path: "/pointWarning/detail",
        name: "pointWarningDetail",
        meta: { title: '积分预警详情页', parent, name: 'name' },
        component: () => import('@/views/PointWarning/detail'),
    },
    {
        path: "/pointWarning/edit",
        name: "pointWarningEdit",
        meta: { title: '积分预警编辑页', parent, name: 'parent' },
        component: () => import('@/views/PointWarning/edit'),
    },
    {
        path: "/pointWarningRecord",
        name: "pointWarningRecord",
        meta: { title: '积分预警记录', parent, name: 'name' },
        component: () => import('@/views/PointWarning/record'),
    },
    {
        path: "/pointWarningIntegral/:start/:end/:mobile",
        name: "pointWarningIntegral",
        meta: { title: '积分记录', parent, name: 'pointWarningRecord' },
        component: () => import('@/components/Member/Integral'),
    },
];