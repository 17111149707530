<template>
    <div class="z-search" :style="{ 'margin-left': `${marginLeft}px`, 'margin-right': `${marginRight}px` }">
        <Dropdown
            v-if="filters.length > 1"
            @on-visible-change="visibleChange"
            @on-click="dropdownChange"
            class="c-top-select"
            size="small"
            placement="bottom-start"
        >
            <Button type="primary" class="search-button" style="display: flex;align-items: center;">
                <span :style="{width: btnTextWidth}">{{ filterLabel }}&nbsp;</span>
                <i :class="{'transform-icon': dropdownVisible}" class="pc-iconfont icon-down-xian"/>
            </Button>
            <DropdownMenu slot="list" placement="left-start">
                <DropdownItem v-for="row in filters" :name="row.value">{{ row.label }}</DropdownItem>
            </DropdownMenu>
        </Dropdown>
        <Button v-else type="primary" class="search-button"><span>{{ filterLabel }}</span></Button>

        <Input
            v-model="params[filterValue]"
            size="small"
            icon="md-search"
            class="c-top-search"
            :placeholder="placeholder || searchPlaceholder"
            @on-click="mdSearch"
            @on-enter="mdSearch"
            :style="{ width: width }"
        />
    </div>
</template>

<script>
    import { getSearchFilters } from "@/viewComponents/ZSearch/data";

    export default {
        name: "index",
        props: {
            type: {
                type: String,
                default: '',
            },
            params: {
                type: Object,
                default: () => {
                    return {}
                },
            },
            width: {
                type: String,
                default: '180px',
            },
            placeholder: {
                type: String,
                default: '',
            },
            marginRight: {
                type: Number,
                default: 0,
            },
            marginLeft: {
                type: Number,
                default: 0,
            },
        },
        data() {
            return {
                dropdownVisible: false,
                filterIndex: 0,
            }
        },
        computed: {
	        filters() {
		        return getSearchFilters(this.type)
	        },
            filterValue() {
                return this.filters[this.filterIndex].value;
            },
            keyword() {
                return this.params[this.filterValue] || '';
            },
            filterLabel() {
                return this.filters[this.filterIndex].label;
            },
            searchPlaceholder() {
                return `输入${this.filterLabel}搜索`;
            },
            btnTextWidth() {
                let _label = '';
                for (let row of this.filters) {
                    if (row.label.length > _label.length) {
                        _label = row.label;
                    }
                }
                return `${_label.length * 12 + 3}px`;
            }
        },
        methods: {
            // 下拉框显示隐藏事件
            visibleChange(bool) {
                this.dropdownVisible = bool;
            },
            // 下拉框 选中
            dropdownChange(key) {
                const hasValue = !!this.params[this.filterValue];
                this.filterIndex = this.filters.findIndex(row => row.value === key);
                for (let row of this.filters) {
                    this.params[row.value] = '';
                }
                if (hasValue) {
                    this.mdSearch();
                }
            },
            mdSearch() {
                this.$emit('change', this.params)
            },

            initHandlerParams(type = this.type) {
                this.filters = getSearchFilters(type);
	            this.filterIndex = 0;
	            for (let i = 0; i < this.filters.length; i++) {
                    const row = this.filters[i];
                    if (this.params[row.value]) {
                        this.filterIndex = i;
                    }
                }
                this.params = Object.assign({}, this.params);
            },
        }
    }
</script>

<style scoped lang="scss">
    .z-search {
        display: flex;
        align-items: center;

        ::v-deep .ivu-btn.ivu-btn-primary {
            padding: 5px 10px 5px 12px;
            background-color: #017DFF;
            border-color: #017DFF;
        }

        ::v-deep .ivu-btn > span {
            display: flex;
            align-items: center;
            justify-content: center;
            vertical-align: middle;
            line-height: 13px;
        }
    }

    .search-button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        box-shadow: none;
    }

    ::v-deep .ivu-input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        box-shadow: none;
    }

    .transform-icon {
        transform: rotateZ(180deg);
    }
</style>