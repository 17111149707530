<template>
    <div v-if="dialogVisible" class="modal dialog-visible">
        <div class="common-model" :class="[fixed ? 'fixed' : 'margin']" :style="{ width: modelWidth }">
            <header class="header">
                <div class="flex-center-bet">
                    <div>
                        <span>{{ text || title || '提示信息' }}</span>
                        <slot name="slot-header-div"></slot>
                    </div>
                    <Icon v-if="close" type="md-close" @click="onCancel()" />
                </div>
                <slot name="header-block"></slot>
            </header>
            <slot name="main-top"></slot>
            <main class="main">
                <div class="main-scroll-bar" :style="{ 'max-height': `${$attrs.height}px`, padding: `${$attrs.padding}` }">
                    <slot name="slot-main"></slot>
                </div>
            </main>
            <slot name="main-footer"></slot>
            <footer v-if="footerShow" class="footer">
                <slot name="slot-footer">
                    <button class="btn-submit" @click="save" :disabled="subStatus">{{ subText }}</button>
                    <button class="btn-submit btn-cancel" v-if="cancel" @click="onCancel()">取消</button>
                </slot>
            </footer>
        </div>
    </div>
</template>

<script>
    /**
     * 二次确认弹窗
     * @param width {string, number} 弹窗宽度
     * @param height {string, number} 中间内容最大高度
     * @param padding {string} 中间内容内边距
     * @param title {string} 提示信息
     * @param fixed {boolean} 绝对定位到页面中间
     * @param subText {string} 确认按钮文案
     * @param footerShow {boolean} 显示/隐藏 确认/取消 按钮 true显示 false隐藏
     * @param save 自定义 确认 事件
     * @param close 自定义 关闭 事件
     * slot:
     * slot-header-div 提示标题下面更多内容显示
     * slot-footer 自定义按钮
     */
    export default {
        name: 'index',
        props: {
            module: {
                type: Boolean,
                default: false,
            },
            text: {
                type: String,
                default: '',
            },
            title: {
                type: String,
                default: '',
            },
            fixed: {
                type: Boolean,
                default: false,
            },
            subStatus: {
                type: Boolean,
                default: false,
            },
            subText: {
                type: String,
                default: '确定',
            },
            footerShow: {
                type: Boolean,
                default: true,
            },
            cancel: {
                type: Boolean,
                default: true,
            },
            close: {
                type: Boolean,
                default: true,
            },
            teleport: {
                type: String,
                default: '',
            },
        },
        data() {
            return {
                dialogVisible: false,
            };
        },
        computed: {
            modelWidth() {
                if (this.$attrs.width === 'auto') {
                    return 'auto'
                } else if (this.$attrs.width === 'fit-content') {
                    return this.$attrs.width
                } else {
                    return this.$attrs.width ? (this.$attrs.width + 'px') : ''
                }
            }
        },
        watch: {
            module: {
                handler(val) {
                    this.dialogVisible = val;
                    // 传送门
                    if (val && this.teleport) {
                        document.querySelector(this.teleport).appendChild(this.$el);
                    }
                },
                immediate: true,
            },
            dialogVisible(val) {
	            this.$emit('update:module', val);
            },
        },
        methods: {
            save() {
                this.$emit('save');
            },
            onCancel() {
                this.$emit('close');
	            this.dialogVisible = false;
            },
        }
    };
</script>

<style scoped lang="scss">
    @import '../../styles/common';

    .dialog-visible {
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }

    .common-model.fixed {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .common-model.margin {
        margin: 150px auto 0;
    }

    .common-model {
        color: #0e3569;
        width: 500px;
        padding: 20px;
        display: inline-block;
        @include radius(4px);
        background-color: #ffffff;
    }

    .header {
        text-align: left;
        font-size: 14px;
        font-weight: bold;
        padding: 0 5px 15px;
        border-bottom: 1px solid #ededed;

        i {
            font-size: 20px;
            cursor: pointer;
            color: #b3c1d8;
        }
    }

    .main {
        text-align: left;
    }

    .main-scroll-bar {
        padding: 20px;
        max-height: 420px;
        overflow-y: auto;
        overflow-x: hidden;

        &::-webkit-scrollbar {
            width: 4px;
            height: 6px;
        }

        &::-webkit-scrollbar-button {
            height: 10px;
            background-color: #dddddd;
            @include radius;
        }

        &::-webkit-scrollbar-track {
            //滚动条背景色
            background-color: #dddddd;
            @include radius;
        }

        &::-webkit-scrollbar-thumb {
            //滚动条颜色
            background-color: #aaaaaa;
            @include radius;
        }
    }

    .footer {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 20px;
        border-top: 1px solid #ededed;

        button + button {
            margin-left: 20px;
        }

        button,
        label {
            width: 88px;
            height: 32px;
            @include radius(4px);
        }

        label {
            cursor: pointer;
            background-color: #017dff;
            color: #ffffff;
            line-height: 32px;
        }

        .btn-cancel {
            background-color: #5a6684;
            border-color: #5a6684;
        }
    }
</style>
