import { getSystemMenu } from "@/api/api";
import { defaultRoute } from "@/utils/common";
import store from "@/store";
import router from "../../router";
import api from "@zhima/api";
import C from "@/components/commen";

export const loginSuccess = (data) => {
    localStorage.setItem('loginSuccess', JSON.stringify(data));
    // 储存用户信息
    localStorage.setItem('unique_member', data.unique_member);
    localStorage.setItem('company_id', data.company_id);
    $.cookie('access_token', data.access_token, 1);
    $.cookie('mall_count', data.mall_count);
    $.cookie('role_id', data.role_id);
    $.cookie('id', data.user_id);
    data.email && $.cookie('email', data.email);  //login接口中没有email字段，异步场景（虽然没找到该场景）可能会导致email被置为undefined，规避
    $.cookie('winshang_uid', data.winshang_uid);

    store.commit('getMallInfoId', $.cookie('mall_id'));

    let before_mall_id = $.cookie('before_mall' + data.user_id);
    if (before_mall_id) {
        let beforeId = data.mall_lists.filter((item) => {
            return item.id == before_mall_id;
        });
        if (beforeId.length && beforeId[0].user_status == 1) {
            //跳转商场页
            $.cookie('mall_id', before_mall_id);
            $.cookie('mall_name', beforeId[0].name);
            if ('company_manager' in data) {
                $.cookie('company_manager', data.company_manager);
            }
            routerLink(data);
        } else if (before_mall_id == -1) {
            $.cookie('id', data.user_id, 1);
            if ('company_manager' in data) {
                $.cookie('company_manager', data.company_manager);
                if (data.company_manager != 1) {
                    $.removeCookie('before_mall' + data.user_id);
                }
                routerLink(data);
            }
        } else {
            $.removeCookie('before_mall' + data.user_id);
            routerLink(data);
        }
    } else {
        routerLink(data);
    }
};

function routerLink(data) {
    let system = data.system;
    let default_route = data.default_route;
    if (system == 4) {
        $.cookie('mall_id', -1);
        localStorage.setItem('mall_edition', '1');
        if ('company_manager' in data) {
            $.cookie('company_manager', data.company_manager);
        }
        if (data.mall_lists && data.mall_lists.length) {
            if (data.unique_member == 1) {
                router.replace('/contentindex')
            } else {
                router.replace('/groupindex')
            }
        } else {
            if (default_route && isHashName(default_route)) {
                $.cookie('default_route', default_route);
                router.replace({ name: default_route })
            } else {
                getSystemMenu({
                    system,
                    mall_id: $.cookie('mall_id')
                }).then(res => {
                    routerDefault(res);
                })
            }
        }
    } else {
        $.cookie('mall_id', data.mall_lists[0].id);
        $.cookie('mall_name', data.mall_lists[0].name);
        if ($.cookie('mall_id')) getPointRule();
        getDataSource();
        localStorage.setItem('mall_edition', '2');
        if (default_route && isHashName(default_route)) {
            $.cookie('default_route', default_route);
            router.replace({ name: default_route })
        } else {
            getSystemMenu({
                system: 5,
                mall_id: $.cookie('mall_id')
            }).then(res => {
                routerDefault(res);
            })
        }
    }
}

function isHashName(name) {
    let routers = router.options.routes;
    let routeArr = [];
    for (let el of routers) {
        if (el.name === 'layout') {
            for (let item of el.children) {
                routeArr.push(item.name);
            }
        }
    }
    return routeArr.includes(name)
}

function routerDefault(res) {
    let _name = defaultRoute(res.data[0]);
    $.cookie('default_route', _name);
    router.replace({ name: _name })
}

function getDataSource() {
    let data_source = $.cookie("data_source");
    if ($.cookie("mall_id") && !data_source) {
        let params = {
            with_data_source: 1
        };
        C._send("/restful/mall/" + $.cookie("mall_id"), params).then(resp => {
            if (resp.data.code == 200) {
                let data = resp.data.data;
                $.cookie("support_pv", data.support_pv);
                $.cookie("data_source", data.data_source);
                $.cookie("deployment_type", data.deployment_type);
                $.cookie("support_miniapp", data.support_miniapp);
                $.cookie("support_alipayapp", data.support_alipayapp);
            }
        }).catch(resp => {
            C.httpStatusAction(resp);
        });
    }
}

function getPointRule() {
    api.getMallPoint_rule({ mall_id: $.cookie("mall_id") }).then(res => {
        let data = res.data;
        sessionStorage.setItem('points_deduction', (data.id ? data.points_deduction : 0));
    })
}