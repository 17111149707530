// ai智能导购管理
const parent = 'aiGuide';

export default [
    // 功能配置
    {
        path: "/functionConfig",
        name: "FunctionConfig",
        meta: { parent, name: 'name' },
        component: () => import('@/views/AIGuide/FunctionConfig'),
    },
    // 对话记录
    {
        path: "/sessionLog",
        name: "SessionLog",
        meta: { parent, name: 'name' },
        component: () => import('@/views/AIGuide/SessionLog'),
    },
    // 对话记录--详情
    {
        path: "/sessionLogDetail",
        name: "SessionLogDetail",
        meta: { parent, name: 'SessionLog' },
        component: () => import('@/views/AIGuide/SessionLog/Detail.vue'),
    },
    //资源管理
    {
        path: "/resourceManage",
        name: "ResourceManage",
        meta: { parent, name: 'name' },
        component: () => import('@/views/AIGuide/ResourceManage/index.vue'),
    },
]