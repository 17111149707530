const parent = 'orderlist';

export default [
    //线上商城订单
    {
        path: "/grouporderlist",
        name: "grouporderlist",
        meta: { parent, name: 'name' },
        component: () => import("@/components/Game/FightGroup/GroupOrderList"),
    },
    {
        path: "/refunddetail",
        name: "refunddetail",
        meta: { parent, name: 'grouporderlist', title: "退款订单详情" },
        component: () => import("@/components/Game/FightGroup/refundDetail"),
    },
    //线上商城订单
    {
        path: "/grouporderdetail",
        name: "grouporderdetail",
        meta: { parent, name: 'grouporderlist' },
        component: () => import("@/components/Game/FightGroup/GroupOrderDetail"),
    },
    // 小程序收银订单
    {
        path: "/cashorder",
        name: "cashorder",
        meta: { parent, name: "name", title: "小程序收银订单" },
        component: () => import("@/components/CashDesk/CashOrder"),
    },
    {
        path: "/cashorderdetail",
        name: "cashorderdetail",
        meta: { parent, name: 'cashorder' },
        component: () => import("@/components/CashDesk/CashOrderDetail"),
    },
    {
        path: "/cashrefunddetail",
        name: "cashrefunddetail",
        meta: { parent, name: 'cashorder' },
        component: () => import("@/components/CashDesk/CashRefundDetail"),
    },
];