const parent = 'goodsmanage';
const quickMeta = { parent, name: 'quickshopping' };
const groupMeta = { parent, name: 'fightgroup' };

export default [
    // {
    //     path: "/quickshopping",
    //     name: "quickshopping",
    //     meta: { parent, name: 'name', title: "闪购商品" },
    //     component: () => import("@/components/QuickShopping/Index"),
    // },
    {
        path: "/quickshopping",
        name: "quickshopping",
        meta: { parent, name: 'name', title: "闪购商品" },
        component: () => import("@/views/GoodsActivity"),
    },
    {
        path: "/fightgroup",
        name: "fightgroup",
        meta: { parent, name: 'name', title: "拼团商品" },
        component: () => import("@/views/GoodsActivity"),
    },
    {
        path: "/couponpackageeidt",
        name: "couponpackageeidt",
        meta: { ...quickMeta, title: "有价券包" },
        component: () => import("@/views/CouponPackage/edit"),
    },
    {
        path: "/couponpackagedetail",
        name: "couponpackagedetail",
        meta: { ...quickMeta, title: "有价券包详情" },
        component: () => import("@/views/CouponPackage/detail"),
    },
    {
        path: "/couponpackageeidtgroup",
        name: "couponpackageeidtgroup",
        meta: { ...groupMeta, title: "有价券包" },
        component: () => import("@/views/CouponPackage/edit"),
    },
    {
        path: "/couponpackagedetailgroup",
        name: "couponpackagedetailgroup",
        meta: { ...groupMeta, title: "有价券包详情" },
        component: () => import("@/views/CouponPackage/detail"),
    },
    {
        path: "/quickshopping/add",
        name: "quickshoppingadd",
        meta: { ...quickMeta, title: "添加商品" },
        component: () => import("@/components/QuickShopping/Add"),
    },
    {
        path: "/quickshopping/detail",
        name: "quickshoppingdetail",
        meta: { ...quickMeta, title: "商品详情" },
        component: () => import("@/components/QuickShopping/Detail"),
    },
    {
        path: "/quickshopping/report",
        name: "quickshoppingreport",
        meta: { ...quickMeta, title: "商品报表" },
        component: () => import("@/components/QuickShopping/Report"),
    },
    {
        path: "/valuablecouponadd",
        name: "valuablecouponadd",
        meta: { ...quickMeta, title: "新增闪购有价券" },
        component: () => import("@/components/ValuableCoupon/ValuableCouponAdd"),
    },
    {
        path: "/valuablecoupondetail",
        name: "valuablecoupondetail",
        meta: { ...quickMeta, title: "闪购有价券详情" },
        component: () => import("@/components/ValuableCoupon/ValuableCouponDetail"),
    },
    // 拼团
    // {
    //     path: "/fightgroup",
    //     name: "fightgroup",
    //     meta: { parent, name: 'name' },
    //     component: () => import("@/components/Game/FightGroup/Index"),
    // },
    {
        path: "/addgroup",
        name: "addgroup",
        meta: groupMeta,
        component: () => import("@/components/Game/FightGroup/Addgroup"),
    },
    // 拼团报表
    {
        path: "/fightreport",
        name: "fightreport",
        meta: groupMeta,
        component: () => import("@/components/Game/FightGroup/FightReport"),
    },
    {
        path: "/groupdetail",
        name: "groupdetail",
        meta: groupMeta,
        component: () => import("@/components/Game/FightGroup/GroupDetail"),
    },
    {
        path: "/valuablecouponaddtwo",
        name: "valuablecouponaddtwo",
        meta: { ...groupMeta, title: "新增拼团有价券" },
        component: () => import("@/components/ValuableCoupon/ValuableCouponAddtwo"),
    },
    {
        path: "/valuablecouponreport",
        name: "valuablecouponreport",
        meta: { ...groupMeta, title: "拼团有价券详情" },
        component: () => import("@/components/ValuableCoupon/ValuableCouponReport"),
    },
];