const parent = 'billmanage';

export default [
    // 商品账单
    {
        path: '/goodsAccount',
        name: 'goodsaccount',
        meta: { title: '传统商品账单', parent, name: 'name' },
        component: () => import('@/views/BillManage/GoodsAccount'),
    },
    {
        path: '/mallGoodsAccount',
        name: 'mallGoodsAccount',
        meta: { title: '传统商场券账单', parent, name: 'name' },
        component: () => import('@/views/BillManage/MallGoodsAccount'),
    },
    {
        path: '/splitAccount',
        name: 'splitaccount',
        meta: { title: '进阶商品账单', parent, name: 'name' },
        component: () => import('@/views/BillManage/SplitAccount'),
    },
    {
        path: '/mallSplitAccount',
        name: 'mallSplitAccount',
        meta: { title: '进阶商场券账单', parent, name: 'name' },
        component: () => import('@/views/BillManage/MallSplitAccount'),
    },
    {
        path: '/capitalFlowTradition',
        name: 'capitalFlowTradition',
        meta: { title: '传统资金流水', parent, name: 'name' },
        component: () => import('@/views/BillManage/CapitalFlowTradition'),
    },
    {
        path: '/capitalFlowMerchant',
        name: 'capitalFlowMerchant',
        meta: { title: '进阶资金流水', parent, name: 'name' },
        component: () => import('@/views/BillManage/CapitalFlowMerchant'),
    },
    {
        path: "/goodsaccountdetail",
        name: "goodsaccountdetail",
        meta: { parent, name: 'goodsaccount' },
        component: () => import('@/components/Account/GoodsAccountDetail'),
    },
    {
        path: '/fareAccount',
        name: 'fareaccount',
        meta: { parent, name: 'name' },
        component: () => import('@/components/Account/FareAccount'),
    },
    {
        path: "/fareaccountdetail", // 运费账单详情
        name: "fareaccountdetail",
        meta: { parent, name: 'fareaccount' },
        component: () => import('@/components/Account/FareAccountDetail'),
    },
    {
        path: "/splitaccountdetail", //进阶分账商品账单详情
        name: "splitaccountdetail",
        meta: { parent, name: 'splitaccount' },
        component: () => import('@/components/Account/SplitAccountDetail'),
    },
    {
        path: "/goodsMallCouponDetail", // 商场券账单详情 传统
        name: "goodsMallCouponDetail",
        meta: { parent, name: 'mallGoodsAccount' },
        component: () => import('@/views/bill/splitMallCouponDetail'),
    },
    {
        path: "/splitMallCouponDetail", //商场券账单详情 进阶
        name: "splitMallCouponDetail",
        meta: { parent, name: 'mallSplitAccount' },
        component: () => import('@/views/bill/splitMallCouponDetail'),
    },
    {
        path: "/capitaldetail", //资金流水详情
        name: "capitaldetail",
        meta: { parent, name: 'capitalFlowTradition' },
        component: () => import('@/views/bill/capitaldetail'),
    },
    {
        path: "/splitcapitaldetail", //资金流水详情
        name: "splitcapitaldetail",
        meta: { parent, name: 'capitalFlowMerchant' },
        component: () => import('@/views/bill/capitaldetail'),
    },
];
