export const getType = type => {
    return obj => {
        return Object.prototype.toString.call(obj).slice(8, -1) == type;
    };
};

export const isString = getType("String");

export const isObject = getType("Object");

export const isArray = getType("Array");

export const isFunction = getType("Function");

export const isUndefined = getType("Undefined");

export const isNull = getType("Null");

export const isNumber = getType("Number");

export const isBoolean = getType("Boolean");

export const isRegExp = getType("RegExp");

// 空字符串包括换行制表符
export const isEmptyString = str => {
    if (!isString(str)) {
        return false;
    }
    let temp = str.trim();
    return str.length == 0;
};

// 判断是否为空
export const isEmpty = data => {
    return isEmptyString(data) || isUndefined(data) || isNull(data);
};

// 判断不为空
export const isNoEmpty = data => !isEmpty(data);

// 判断是否是基本数据类型
export const isBasicType = val => {
    return isString(val) || isNumber(val) || isBoolean(val);
};

export const greaterThanZero = val => {
    return parseInt(val) > 0;
};

// 检测数据类型
export const checkType = obj => {
    return Object.prototype.toString
        .call(obj)
        .slice(8, -1)
        .toLowerCase();
};
// 深拷贝
export function deepCopy(obj) {
    let result;
    const type = checkType(obj);
    if (type === "array" || type === "object") {
        result = type === "array" ? [] : {};
        Object.keys(obj).forEach(index => {
            if (typeof obj[index] === "object" && obj[index] !== null) {
                result[index] = deepCopy(obj[index]);
            } else {
                result[index] = obj[index];
            }
        });
    } else {
        result = obj;
    }
    return result;
}
// 节流函数
export const throttle = (fn, t) => {
    let last;
    let timer;
    let interval = t || 500;
    return function() {
        let args = arguments;
        let now = +new Date();
        if (last && now - last < interval) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                last = now;
                fn.apply(this, args);
            }, interval);
        } else {
            last = now;
            fn.apply(this, args);
        }
    };
};

// 返回今日，或今日之前的日期
// day：0，今日；day：1，昨日；day：2，前日
export const getDate = day => {
    const _time = 24 * 60 * 60 * 1000 * day;
    let _day = new Date();
    _day.setTime(_day.getTime() - _time);
    let mouth =
        _day.getMonth() + 1 < 10
            ? `0${_day.getMonth() + 1}`
            : _day.getMonth() + 1;
    let new_day = _day.getDate() < 10 ? `0${_day.getDate()}` : _day.getDate();
    return _day.getFullYear() + "-" + mouth + "-" + new_day;
};
