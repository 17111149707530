const parent = 'sendcoupon';

export default [
    //定向发券
    {
        path: "/sendcoupon",
        name: "sendcoupon",
        component: () => import('@/components/Coupon/SendCoupon'),
    },
    //定向发券新增编辑
    {
        path: "/sendcouponadd",
        name: "sendcouponadd",
        meta: { parent, name: parent },
        component: () => import('@/views/sendCouponScene/editFixed'),
    },
    {
        path: "/sendcouponadds",
        name: "sendcouponadds",
        meta: { parent, name: parent },
        component: () => import('@/components/Coupon/SendCouponAdds'),
    },
    {
        path: "/sendcouponsceneedit",
        name: "sendcouponsceneedit",
        meta: { parent, name: parent },
        component: () => import('@/views/sendCouponScene/edit'),
    },
    //定向发券
    {
        path: "/sendcouponreport",
        name: "sendcouponreport",
        meta: { parent, name: parent },
        component: () => import('@/components/Coupon/SendCouponReport'),
    },
    // {
    //     path: "/sendplandetail",
    //     name: "sendplandetail",
    //     meta: { parent, name: parent },
    //     component: () => import('@/components/Coupon/SendplanDetail'),
    // },
    {
        path: "/sendplandetail",
        name: "sendplandetail",
        meta: { parent, name: parent },
        component: () => import('@/views/sendCouponScene/detail'),
    },
]