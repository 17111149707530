import {isPaidMember} from "@/utils/manage";

const USER_SIGNIN = 'USER_SIGNIN'
const USER_SIGNOUT = 'USER_SIGNOUT'

const state = {
    login: JSON.parse(sessionStorage.getItem('user')) || {},
    report: 0,
    company_logourl: '',
    feedback_ticket: 1,
    mallInfoImage: {
        code: '',
        logo: '',
        title: '',
    },
    isPaidMember: isPaidMember()
}

const getters = {}

const actions = {}

const mutations = {
    [USER_SIGNIN](state, user) {
        sessionStorage.setItem('user', JSON.stringify(user))
        Object.assign(state, {login: user})
    },
    [USER_SIGNOUT](state, user) {
        sessionStorage.removeItem('user')
        Object.assign(state, {login: user})
    },
    increment(state) {
        // 变更状态
        state.report = 1
    },
    replace(state, n) {
        state.company_logourl = n
    },
    resetTicket(state, n) {
        state.feedback_ticket = n
    },
    getMallInfoId(state, id) {
        switch (String(id)) {
            case '2371'://五彩城
                state.mallInfoImage.code = 'http://cdn.zhimatech.com/wechatjigou-wcc-qcode.jpg';
                state.mallInfoImage.logo = 'http://cdn.zhimatech.com/wechatjigou-wcc-logo.jpeg';
                state.mallInfoImage.title = '北京华润五彩城';
                break;
            case '2380'://天宁
                state.mallInfoImage.code = 'http://cdn.zhimatech.com/wechatjigou-tianning-qcode.jpg';
                state.mallInfoImage.logo = 'http://cdn.zhimatech.com/wechatjigou-wuyue-logo.png';
                state.mallInfoImage.title = '天宁吾悦广场';
                break;
            default://及购
                // state.mallInfoImage.code = 'http://cdn.zhimatech.com/beiweijigou.svg';
                // state.mallInfoImage.logo = 'http://cdn.zhimatech.com/jigou_icon.svg';
                state.mallInfoImage.code = 'http://cdn.zhimatech.com/molli2-qcode-demo.png';
                state.mallInfoImage.logo = $.cookie("mall_logo");
                state.mallInfoImage.title =  $.cookie("mall_name");
                break;
        }

    }
};

export default {
    state,
    getters,
    actions,
    mutations
}
