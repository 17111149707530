// 兑换码 （华联定制-付费会员第三方推广）
const parent = 'redeemCode';

export default [
    // 兑换码管理
    {
        path: "/redeemCodeList",
        name: "redeemCodeList",
        meta: { parent, name: 'name' },
        component: () => import('@/views/redeemCode/redeemCodeList'),
    },
    // 生成兑换码
    {
        path: "/redeemCodeAdd",
        name: "redeemCodeAdd",
        meta: { parent, name: 'redeemCodeList' },
        component: () => import('@/views/redeemCode/redeemCodeAdd'),
    },
    // 兑换码数据报表
    {
        path: "/redeemCodeReport",
        name: "redeemCodeReport",
        meta: { parent, name: 'redeemCodeList' },
        component: () => import('@/views/redeemCode/redeemCodeReport'),
    },
    // 兑换记录
    {
        path: "/exchangeRecord",
        name: "exchangeRecord",
        meta: { parent, name: 'name' },
        component: () => import('@/views/redeemCode/exchangeRecord'),
    },
    // 作废记录
    {
        path: "/cancellationRecord",
        name: "cancellationRecord",
        meta: { parent, name: 'name' },
        component: () => import('@/views/redeemCode/cancellationRecord'),
    },
]