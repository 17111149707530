const parent = 'accountmanage';
const _name = { parent, name: 'name' };

export default [
    // 账号设置
    {
        path: "/accountset",
        name: "accountset",
        meta: _name,
        component: () => import('@/components/Config/Accountset'),
    },
    // 角色设置
    {
        path: "/roleset",
        name: "roleset",
        meta: _name,
        component: () => import("@/components/Role/Roleset"),
    },
    // 角色管理
    {
        path: "/role/index",
        name: "role",
        meta: _name,
        component: () => import('@/views/account/role'),
    },
    // 新增角色账号
    {
        path: "/role/edit",
        name: "roleedit",
        meta: { parent, name: 'role' },
        component: () => import('@/views/account/role/edit'),
    },
    // 角色详情
    {
        path: "/role/detail",
        name: "roledetail",
        meta: { parent, name: 'role' },
        component: () => import('@/views/account/role/detail'),
    },
    // 账号管理
    {
        path: "/account/index",
        name: "account",
        meta: _name,
        component: () => import('@/views/account/account'),
    },
    // 新增账号
    {
        path: "/account/edit",
        name: "neweditaccount",
        meta: { parent, name: 'account' },
        component: () => import('@/views/account/account/edit'),
    },
    // 账号详情
    {
        path: "/account/detail",
        name: "accountdetail",
        meta: { parent, name: 'account' },
        component: () => import('@/views/account/account/detail'),
    },
];
