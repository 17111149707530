import api from "@zhima/api";
import { postV3Api } from "@/api/api";
import initOss from "@/utils/dockOss";

const storage = window.sessionStorage.getItem('couponParams');

const state = {
    initOss: null,
    inUploadCount: 0,

    paidMembers: [],// 会员卡卡面信息
    layerStoreComponents: {},// 中间预览部分 数组
    layerStoreComponentErrorId: '',// 表单验证未通过的组件id 拖拽组件
    dragStoreId: {},// 点击中预览部分获取id(可拖拽部分)
    fixedStoreComponents: [],// 固定组件
    fixedStoreIndex: '',// 基础配置/固定位置 下标(不可拖拽部分)
    fixedStoreErrorIndex: '',// 基础配置/固定位置 下标(不可拖拽组件) 错误提示
    tabStore: 0,// 切换主题/会员
    initialIndex: 0,// 切换主题/会员
    storeSubStatus: false,// 错误开启/关闭 默认false 关闭

    mall_config: {},
    applets: storage ? JSON.parse(storage).applets : 0,
    systemNotificationNum: 0,// 系统通知条数
    buttonPermissions: [],// 用户按钮权限
    apiNumber: 0,

    parkConfig: {},// 宙斯停车设置
};

const getters = {};

const actions = {
    async getInitOss({ commit }) {
        let _oss = await initOss();
        commit('setInitOss', _oss);
        return _oss;
    },
    getMallConfig({ commit }) {
        api.getMallConfig({
            mall_id: $.cookie("mall_id")
        }).then(async res => {
            if (res.code === 200) {
                const { data } = res;
                commit('SET_MALL_CONFIG', data);
            }
        }).catch(error => {
            console.error(error);
        });
    },
    getSystemNotification({ commit }, num = 0) {
        const data = {
            page: 1,
            per_page: 0,
            status: 2,
            user_id: $.cookie('id'),
        };
        postV3Api('userInform.list', data).then(res => {
            commit('setSystemNotification', res.meta.total || num);
        }).catch(_ => {

        });
    }
};

const mutations = {
    // 会员卡卡面信息
    SET_PAID_MEMBERS(state, value) {
        state.paidMembers = value;
    },
    // 固定组件原数据
    SET_LAYER_FIXED_COMPONENTS(state, value) {
        state.fixedStoreComponents = value;
    },
    // 固定组件原数据 选中对应下标
    SET_LAYER_FIXED_INDEX(state, index) {
        state.fixedStoreIndex = index;
    },
    // 固定组件原数据 选中对应表单错误下标
    SET_LAYER_FIXED_ERROR_INDEX(state, index) {
        state.fixedStoreErrorIndex = index;
    },
    // 月/季/年卡切换
    SET_TAB_ID(state, index) {
        state.tabStore = index;
    },
    // 轮播切换
    SET_INITIAL_INDEX(state, index) {
        state.initialIndex = index;
    },
    // 表单提交
    SET_SUB_STATUS(state, bool) {
        state.storeSubStatus = bool;
    },
    // 拖动组件 id
    SET_LAYER_ID(state, id) {
        state.dragStoreId = id;
    },
    // 拖拽组件集合
    SET_LAYER_COMPONENTS(state, value) {
        state.layerStoreComponents = value;
    },
    // 表单验证未通过的组件id
    SET_LAYER_COMPONENT_ERROR_ID(state, value) {
        state.layerStoreComponentErrorId = value;
    },
    // 初始化oss
    setInitOss(state, value) {
        state.initOss = value;
    },
    // 初始化oss
    resetInitOss(state) {
        state.initOss = null;
    },
    addApiNumber() {
        state.apiNumber++;
    },
    removeApiNumber() {
        state.apiNumber--;
    },
    resetApiNumber(state) {
        state.apiNumber = 0;
    },
    // 正在上传的数量
    setInUploadCount(state, type = 'rest') {
        if (type === 'reset') {
            state.inUploadCount = 0;
        } else if (type === 'add') {
            state.inUploadCount++;
        } else {
            if (state.inUploadCount > 0) {
                state.inUploadCount--;
            }
        }
    },
    SET_MALL_CONFIG: (state, config) => {
        state.mall_config = config;
    },
    SET_APPLETS: (state, val = 0) => {
        state.applets = val;
    },
    setSystemNotification: (state, num = 0) => {
        state.systemNotificationNum = num;
    },
    setButtonPermissions: (state, value = '') => {
        if (value && !state.buttonPermissions.includes(value)) {
            state.buttonPermissions.push(value);
        }
    },
    resetButtonPermissions: (state) => {
        state.buttonPermissions = []
    },
    setParkConfig: (state, config) => {
        state.parkConfig = config || {}
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}
